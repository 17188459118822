import React from "react";
import { Link } from "react-router-dom";

function GetInTouch() {
  return (
    <div className="mt-24  border mx-24 max-md:mx-6 rounded-3xl  bg-88D3CD flex flex-col items-center ">
      <div className="text-4xl mt-10  font-semibold tracking-wider capitalize text-slate-900 
        get-in-touch-container
      ">
        Got a query? Get in touch
      </div>
      {/* mx-24 text-xl font-light leading-7 text-center text-black max-md:max-w-full */}
      <div className="p-10 max-md:p-10  text-xl font-light">
        We understand that you may have questions or concerns. We would like to
        address <br /> them to the best of our ability. Write to us at
      </div>
      <br />
      <br />
      <Link to="/login">
        <div className="justify-center mb-10  self-stretch px-10 py-3.5 my-auto font-bold text-white whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5">
          admin@cure-culture.com
        </div>
      </Link>
    </div>
  );
}

export default GetInTouch;
