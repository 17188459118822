import React from "react";

function BillingDetails() {
  return (
    <section
      className="my-4 text-dark_tone capitalize"
      aria-label="Billing Details"
      id="Billing Details"
    >
      <div className="text-2xl my-2 text-color-1 font-semibold ">
        Billing Details
      </div>
      <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl my-4 shadow-lg h-52">
        To be concluded by dev team  
      </div>
    </section>
  );
}

export default BillingDetails;
