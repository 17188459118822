import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import ProfilePicture from "../../../../components/ProfilePicture";
import Rating from "@mui/material/Rating";

function MyStatesWidget() {
  const item = [1, 2, 3, 4];

  return (
    <div className="bg-white  shadow-xl   rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-96">
      <div className="text-subtitle_one">My Stats</div>
      <div className="h-96">Some data</div>
      <Divider />
      <div className="flex justify-end my-1">
        <Link
          to="/provider/care-collaboration/details"
          className="flex justify-end mb-2"
        >
          <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
            View Details
          </button>
        </Link>
      </div>
    </div>
  );
}

export default MyStatesWidget;
