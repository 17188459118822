import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useForm } from "react-hook-form";
import SelfdiagnosisSpecialties from "./SelfdiagnosisSpecialties";
import SelfdiagnosisQuestionnaire from "./SelfdiagnosisQuestionnaire";

function Selfdiagnosis() {
  const [visitedProvider, setIsVisitedProvider] = useState("");
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    // setNewData([...newData, data]);
    // reset();
  };
  const [saving, setSaving] = useState(false);
  const [inputData, setInputData] = useState({
    current_diagnosis: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };
  const onSave = () => {
    // setSaving(true);
    // const payload = {
    //   memberships: [...membershipData, ...newData].map(
    //     ({ id, institution_name, start_year, end_year }) => ({
    //       id,
    //       institution_name,
    //       start_year,
    //       end_year,
    //     })
    //   ),
    // };
    // saveProfessionalMembership(payload).then(() => {
    //   setSaving(false);
    //   setNewData([]);
    //   getData();
    // });
  };

  return (
    <section
      className="my-4 text-dark_tone capitalize"
      aria-label="Self-diagnosis"
      id="Self-diagnosis"
    >
      <div className="text-2xl my-2 text-color-1 font-semibold ">
        Self-diagnosis
      </div>
      <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl  shadow-lg my-2">
        <div className="text-subtitle_one pb-2 max-md:px-2 ">
          Have you been to a provider for your current condition?
        </div>
        <div>
          <FormControl>
            <RadioGroup
              onChange={(e) => setIsVisitedProvider(e.target.value)}
              aria-labelledby="Have you been to a provider for your current condition?"
            >
              <div className="flex gap-4 items-center">
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </div>
            </RadioGroup>
          </FormControl>
        </div>
        {visitedProvider === "yes" ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-gray_out p-2  rounded-md my-2">
                <div className="my-4 flex  items-center max-md:flex-col max-md:gap-3 gap-2">
                  <div>
                    <label htmlFor="Name of Previous provider">
                      Name of Previous provider
                    </label>
                    <input
                      onChange={handleChange}
                      name="previous_provider"
                      id="Name of Previous provider"
                      className="w-96 border p-3 rounded-md  max-md:w-80"
                    />
                  </div>
                  <div>
                    <label htmlFor="Clinic Name">Clinic Name</label>
                    <input
                      onChange={handleChange}
                      name="clinic_name"
                      id="Clinic Name"
                      type="text"
                      className="w-96 border p-3 rounded-md  max-md:w-80"
                    />
                  </div>

                  <div>
                    <label htmlFor="City - State">City - State</label>
                    <input
                      onChange={handleChange}
                      name="city_state"
                      id="City - State"
                      type="text"
                      className="w-96 border p-3 rounded-md  max-md:w-80"
                    />
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : null}
        <div className="text-subtitle_one pb-2 mt-3 max-md:px-2 ">
          Do you know your current diagnosis?
        </div>
        <div>
          <FormControl>
            <RadioGroup
              name="current_diagnosis"
              onChange={handleChange}
              aria-labelledby="Do you know your current diagnosis?"
            >
              <div className="flex flex-col ">
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="I don't know"
                />
                {inputData.current_diagnosis === "no" ? (
                  <SelfdiagnosisQuestionnaire />
                ) : null}
                <FormControlLabel
                  value="maybe"
                  control={<Radio />}
                  label="I suspect might be"
                />
                {inputData.current_diagnosis === "maybe" ? (
                  <SelfdiagnosisSpecialties
                    selectedSpecialties={selectedSpecialties}
                    setSelectedSpecialties={setSelectedSpecialties}
                  />
                ) : null}

                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="I am diagnosed with"
                />
                {inputData.current_diagnosis === "yes" ? (
                  <SelfdiagnosisSpecialties
                    selectedSpecialties={selectedSpecialties}
                    setSelectedSpecialties={setSelectedSpecialties}
                  />
                ) : null}
              </div>
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </section>
  );
}

export default Selfdiagnosis;
