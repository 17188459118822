import React from "react";

function Eligibility() {
  return (
    <div className="mt-36 ml-5 text-4xl font-bold tracking-wider text-center capitalize text-slate-900 max-md:mt-10 max-md:max-w-full">
      <img
        alt=""
        loading="lazy"
        src=""
        className="mt-11 w-44 max-w-full aspect-[25] max-md:mt-10"
      />
      <div className=" mt-32 text-4xl font-bold tracking-wider text-center capitalize text-slate-900 max-md:mt-10 max-md:max-w-full">
        What it takes to be a provider
      </div>
      <br />
      <div className="my-4 text-button_font  leading-8 text-center text-black capitalize max-md:mt-10 max-md:max-w-full">
        To be eligible as a provider on this platform, the following
        qualification is mandatory.
      </div>
      <div className="px-5 flex  justify-center mt-24 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex mx-20 max-md:mx-0 flex-gap-10 max-md:flex-col  max-md:gap-4 ">
          <div className="flex border gap-10  eligibility-bg rounded-3xl flex-col  max-md:ml-0 max-md:w-full">
            <div className="flex flex-col  p-8 items-center grow px-14 py-12 w-full text-2xl tracking-wide text-center capitalize rounded-3xl  text-slate-900 max-md:px-5 max-md:mt-8">
              <img
                alt="assets/images/img-green-tick-001.png"
                loading="lazy"
                height={96}
                width={96}
                src="/assets/images/img-green-tick-001.png"
                className="self-center mt-4 w-24 aspect-square"
              />
              <div className="max-md:mt-10 text-body_font_one">
                MD/DO, PA, or Nursing degree majoring in Psychiatry.
              </div>
            </div>
          </div>
          <div className="flex border p-8 eligibility-bg rounded-3xl flex-col ml-5  max-md:ml-0 max-md:mt-t max-md:w-full ">
            <div className="flex flex-col items-center grow py-12 w-full text-2xl tracking-wide text-center capitalize   text-slate-900 max-md:px-5 max-md:mt-8">
              <img
                alt=""
                height={96}
                width={96}
                loading="lazy"
                src="/assets/images/img-green-tick-001.png"
                className="self-center  mt-2 w-24 aspect-square"
              />
              <div className=" max-md:mt-10 text-body_font_one">
                Bachelors, Masters or PHD majoring in Psychology.
              </div>
            </div>
          </div>
          <div className="flex border  flex-col  eligibility-bg rounded-3xl  ml-5  max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-center p-12 text-2xl tracking-wide text-center capitalize rounded-3xl  max-md:px-5 max-md:mt-8">
              <img
                alt=""
                height={96}
                width={96}
                loading="lazy"
                src="/assets/images/img-green-tick-001.png"
                className="self-center mt-2 w-24 aspect-square"
              />
              <div className="  max-md:mt-10 text-body_font_one">
                Master`s in Social Work, Therapy or Counselling.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eligibility;
