import React from "react";

import Select from "react-select";
import classNames from "classnames";
function MyStatesApp() {
  return (
    <div className="bg-white w-full h-48 rounded shadow-lg max-md:h-full  max-md:w-full">
      <div className="flex gap-1.5 items-start mt-4  max-md:flex-col max-md:px-4">
        <div className="text-subtitle_one px-4 ">My Stats</div>
        <Select
          classNames={{
            control: () => classNames({}, "w-36 rounded-lg   rounded-xl"),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          defaultValue={{
            label: "14 Days",
            value: "",
          }}
          options={[
            {
              label: "14 Days",
              value: "1",
            },
            {
              label: "Last month",
              value: "2",
            },
            {
              label: "Last 12 Month",
              value: "3",
            },
            {
              label: "All Time",
              value: "4",
            },
          ]}
        />
      </div>
      <br />
      <div className="flex justify-between max-md:flex-col max-md:gap-4 max-md:items-start max-md:py-4">
        <div className="flex px-4 ">
          <img
            src={"/assets/icons/provider/my-states-in-progress-icon.png"}
            className="w-14 h-14 mr-5"
          />
          <div className="border-l w-1 border-gray-400"></div> &nbsp;
          <div>
            <span className="text-xxl block ">07%</span>
            <span className="text-gray_out_text">Target (Mandated)</span>
          </div>
        </div>
        <div className="flex px-4">
          <img
            src={"/assets/icons/provider/my-states-in-progress-icon.png"}
            className="w-14 h-14 mr-5"
          />
          <div className="border-l w-1 border-gray-400"></div> &nbsp;
          <div>
            <span className="text-xxl block ">2.4 %</span>
            <span className="text-gray_out_text">My Current Score</span>
          </div>
        </div>
        <div className="flex px-4">
          <img
            src={"/assets/icons/provider/my-states-in-progress-icon.png"}
            className="w-14 h-14 mr-5"
          />
          <div className="border-l w-1 border-gray-400"></div> &nbsp;
          <div>
            <span className="text-xxl block ">$30</span>
            <span className="text-gray_out_text">Cost (Amount Spent)</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyStatesApp;
