import React, { useState } from "react";
import Tabs from "./Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faSearch,
  faStar,
  faStarHalfStroke,
} from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import { uniqueId } from "lodash";
import Box from "@mui/material/Box";
import { fakeMessage } from "./utils";

const MainContent = () => {
  const [activeTab, setActiveTab] = useState("Primary");

  const getEmails = (tab) => {
    switch (tab) {
      case "Primary":
        return fakeMessage;
      case "Updates":
        return [];
      case "Social":
        return [
          /* Array of social emails */
        ];
      default:
        return [];
    }
  };

  const emails = getEmails(activeTab);

  //   <div class="grid grid-cols-4 gap-4">
  //   <div>01</div>
  //   <!-- ... -->
  //   <div>09</div>
  // </div>
  const columns = [
    { field: "senderName", headerName: "From", width: 200, flex: 1 },
    { field: "subject", headerName: "Subject", width: 250, flex: 2 },
    { field: "message", headerName: "Message", width: 450, flex: 3 },
    { field: "date", headerName: "Date", width: 100, flex: 0 },
  ];
  return (
    <div className="flex-1 p-4 w-full max-md:p-0">
      <div className="flex justify-between max-md:flex-col">
        <h1 className="text-xxl font-semibold">Communication Centre</h1>
        <div className="flex items-center gap-2">
          <div className="bg-light_tone_one p-2 flex gap-1 items-center rounded-lg shadow-sm">
            <FontAwesomeIcon
              icon={faSearch}
              className="h-5 w-5 text-gray-400 border  rounded-full "
              aria-hidden="true"
            />
            <input
              type="search"
              className="bg-light_tone_one p-1"
              placeholder="Search Communication"
            />
          </div>
          <span className="material-icons cursor-pointer">
            <FontAwesomeIcon
              icon={faGear}
              className="h-5 w-5 text-gray-400 border  rounded-full "
              aria-hidden="true"
            />
          </span>
        </div>
      </div>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Box sx={{ height: "70vh", width: "100%" }}>
        <DataGrid
          key={uniqueId()}
          checkboxSelection
          rows={emails}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 20, 100]}
        />
      </Box>
    </div>
  );
};

export default MainContent;
