import React, { useState } from "react";
import { CarouselComponent } from "../provider/HomePageOne";

function UnderMaintenance() {
  const [activeIndex, setActiveIndex] = useState(0);

  const data = [
    {
      heading: {
        content: `Built by Providers, for Providers.
    We are
    Cure Culture.`,
        style: "text-4xl",
      },
      subHeading: {
        content: ``,
        style: "",
      },
      subSubHeading: {
        content: `A custom platform for Providers to take their private practice
    online. Our fully automated platform will provide support at every
    step.`,
        style: "",
      },
      src: "/assets/images/main-page-img-01.png",
    },
    {
      heading: { content: `You First.`, style: " text-5xl " },
      subHeading: {
        content: `Cure Culture is the most

      provider-centric platform around.`,
        style: "text-3xl leading-10 ",
      },
      subSubHeading: {
        content: `It is designed to address the daily challenges encountered by healthcare providers.
       The platform was entirely based on the feedback and input from providers. This makes it a platform that prioritises the welfare and  needs of a provider.`,
        src: "/assets/images/main-page-img-01.png",
        style: "text-button_font",
      },
      src: "/assets/images/main-page-img-01.png",
    },
  ];

  return (
    <div>
      <div className="flex justify-center flex-col items-center gap-3 pt-20 max-md:items-start max-md:px-10">
        <img width={300} src="/assets/images/logo.png" alt="logo" />
        <br />
        <h1 className="text-5xl">Cure-Culture is under Maintenance!</h1>
        <h3 className="text-body_font_one">
          Please Check back again in some time
        </h3>
        <div className="mt-36 pl-11 max-md:p-2  w-full   max-md:mt-0 max-md:max-w-full">
          <div
            className="
      flex gap-5 justify-end 
      
      max-md:flex-col max-md:gap-0 "
          >
            <div className="flex flex-col w-[43%] h-[75dvh] max-md:h-full max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow mt-4 text-xl max-md:mt-10 max-md:max-w-full">
                {activeIndex === 0 ? (
                  <div className="text-6xl font-semibold tracking-wider capitalize leading-[78px] text-slate-900 max-md:max-w-full max-md:text-4xl max-md:leading-[58px]">
                    Built by Providers, for Providers. <br />
                    We are <br />
                    Cure Culture.
                  </div>
                ) : (
                  <div>
                    <div className="text-6xl font-semibold tracking-wider capitalize leading-[78px] text-slate-900 max-md:max-w-full max-md:text-4xl max-md:leading-[58px]">
                      You First.
                    </div>
                    <br />
                    <div className="font-semibold text-3xl">
                      Cure Culture is the most provider-centric platform around.
                    </div>
                  </div>
                )}
                <div className="mt-10 w-4/5 font-light leading-7 text-black max-md:mt-10 max-md:max-w-full text-body_font_one">
                  It is designed to address the daily challenges encountered by
                  healthcare providers. The platform was entirely based on
                  the feedback and input from providers. This makes it a
                  platform that prioritises the welfare and  needs of a
                  provider.
                </div>
              </div>
            </div>
            <div className="flex  flex-col ml-5 w-[57%] max-md:ml-0 max-md:w-full">
              <CarouselComponent items={data} onChange={setActiveIndex} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderMaintenance;
