import React from "react";
import DashboardHoc from "../../DashboardHoc";
import Messaging from "../../../common/messageing/Messaging";

function ProviderMessaging() {
  return (
    <DashboardHoc>
      <div className="bg-white border-t w-4/5 max-md:m-h-96 max-md:overflow-auto max-md:w-full">
        <Messaging />
      </div>
    </DashboardHoc>
  );
}

export default ProviderMessaging;
