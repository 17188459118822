import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormInputComponent from "./FormInputComponent";
import FormButtonComponent from "./FormButtonComponent";
import { AuthContext } from "../../../App";
import { useLocation } from "react-router-dom";
import useSaveRoute from "../../utils/useSaveRoute";
import useUserActions from "../../../actions/useUserActions";
import classNames from "classnames";
import StatePicker from "./StatePicker";
import { PREFIXES, SUFFIXES } from "./utils";
// const CryptoJS = require("crypto-js");
import Select from "react-select";

function PersonalDetailsVerification({
  onSubmit,
  isEditing,
  setIsEditing,
  handleChange,
  userDetails,
}) {
  const { getStateByZip } = useUserActions();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
  } = useForm();
  if (!userDetails) return;

  // const decryptedFirstName = CryptoJS.AES.decrypt(
  //   userDetails.decrypt ? userDetails.first_name : "",
  //   process.env.REACT_APP_CRYPTO_KEY
  // ).toString();
  const handleZIPchange = async (zipValue) => {
    if (zipValue.length === 5) {
      const zipApiResponse = await getStateByZip(zipValue);
      // console.log(zipApiResponse);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="max-md:mx-10 ">
      <div className="text-2xl py-10  ">Personal Details Verification</div>
      <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl  shadow-lg">
        <div className="text-body_font_two pb-2 max-md:px-2 ">Confirm Name</div>
        <div
          className="
        flex gap-5 justify-start max-md:px-2  max-md:flex-col flex-wrap w-full"
        >
          <div>
            <label
              className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
              htmlFor={"prefix"}
            >
              Prefix
            </label>

            <Select
              options={PREFIXES}
              className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
              id="prefix"
              name="prefix"
              placeholder=""
              isDisabled={!isEditing}
              onChange={(e) => handleChange("prefix", e.value)}
              defaultValue={PREFIXES.find(
                (x) => x.value === userDetails.prefix
              )}
            />
          </div>
          <FormInputComponent
            register={register}
            inputFor={"firstName"}
            isDisabled={!isEditing}
            inputName="first_name"
            inputLabel={"First Name"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="firstName"
            inputAutocomplete="firstName"
            validations={{ required: true }}
            inputPlaceholder=""
            defaultValue={userDetails.first_name}
          />{" "}
          <FormInputComponent
            isDisabled={!isEditing}
            register={register}
            inputFor={"midName"}
            inputName="middle_name"
            inputLabel={"Middle Name"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="midName"
            inputAutocomplete="midName"
            // validations={{ required: false }}
            inputPlaceholderP=""
            defaultValue={userDetails.middle_name}
          />{" "}
          <FormInputComponent
            register={register}
            inputFor={"lastName"}
            inputName="last_name"
            inputLabel={"Last Name"}
            inputType="text"
            className="w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="lastName"
            inputAutocomplete="lastName"
            validations={{ required: true }}
            defaultValue={userDetails.last_name}
            inputPlaceholder=""
            isDisabled={!isEditing}
          />
          <div>
            <label
              className="block text-sm font-medium leading-6 text-mid_tone_one mb-2"
              htmlFor={"suffix"}
            >
              Suffix
            </label>
            <Select
              options={SUFFIXES}
              className="w-36 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 "
              id="suffix"
              placeholder=""
              isDisabled={!isEditing}
              onChange={(e) => handleChange("suffix", e.value)}
              defaultValue={SUFFIXES.find(
                (x) => x.value === userDetails.suffix
              )}
            />
          </div>
        </div>

        <div className="mt-2  flex justify-start gap-3 max-md:flex-col max-md:px-2">
          <FormInputComponent
            inputFor={"email"}
            inputName="email"
            inputLabel={"Email id"}
            inputType="email"
            className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="email"
            inputAutocomplete="email"
            inputPlaceholderP=""
            defaultValue={userDetails.email}
            isDisabled={true}
          />{" "}
          <FormInputComponent
            register={register}
            isDisabled={!isEditing}
            inputFor={"phone"}
            inputName="phone_number"
            inputLabel={"Phone"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent   text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="phone"
            inputAutocomplete="phone"
            validations={{ required: true }}
            inputPlaceholderP=""
            defaultValue={userDetails.phone_number}
          />
        </div>

        <div className="flex gap-5 mt-10 flex-wrap w-2/3">
          <FormInputComponent
            register={register}
            inputFor={"address_line1"}
            inputName="address_line1"
            inputLabel={"Address Line 1"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="address_line1"
            inputAutocomplete="address_line1"
            // validations={{ required: true }}
            inputPlaceholder=""
            defaultValue={userDetails["address_line1"]}
            isDisabled={!isEditing}
          />
          <FormInputComponent
            register={register}
            inputFor={"address_line2"}
            inputName="address_line2"
            inputLabel={"Address Line 2"}
            inputType="text"
            className="block w-72 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="address_line2"
            inputAutocomplete="address_line2"
            // validations={{ required: true }}
            inputPlaceholder=""
            defaultValue={userDetails["address_line2"]}
            isDisabled={!isEditing}
          />
          <FormInputComponent
            register={register}
            inputFor={"city"}
            inputName="city"
            inputLabel={"City"}
            inputType="text"
            className="block w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 max-md:w-full"
            inputId="city"
            inputAutocomplete="city"
            validations={{ required: true }}
            inputPlaceholder=""
            defaultValue={userDetails["city"]}
            isDisabled={!isEditing}
          />
          <FormInputComponent
            register={register}
            inputFor={"zip_code"}
            inputName="zip_code"
            inputLabel={"Zip Code"}
            inputType="text"
            // inputPattern="[0-9]"
            className="block w-48 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 "
            inputId="zip_code"
            inputAutocomplete="zip_code"
            validations={{ required: true }}
            inputPlaceholder=""
            handleChange={(e) => handleZIPchange(e.target.value)}
            defaultValue={userDetails["zip_code"]}
            isDisabled={!isEditing}
          />
          <div>
            <StatePicker
              onChange={(val) => handleChange("state", val)}
              isDisabled={!isEditing}
            />
          </div>
        </div>
        <br />
        <FormInputComponent
          register={register}
          inputFor={"ssn"}
          inputName="ssn"
          inputLabel={"Social Security Number"}
          inputType="text"
          className="block w-1/3 border-2 rounded-xl  bg-transparent text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 max-md:w-full"
          inputId="ssn"
          inputAutocomplete="ssn"
          validations={{ required: true }}
          inputPlaceholder=""
          defaultValue={userDetails["ssn"]}
          isDisabled={!isEditing}
        />

        <div className="mt-2 flex gap-3 max-md:px-2 ">
          <FormButtonComponent
            ButtonType="button"
            onClick={() => setIsEditing(true)}
            className="text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one bg-mid_tone_two submit-btn-bg-color"
            ButtonValue="Edit"
          ></FormButtonComponent>
          {isEditing ? (
            <FormButtonComponent
              ButtonType="submit"
              className={classNames(
                "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one  submit-btn-bg-color"
              )}
              ButtonValue="Confirm"
            ></FormButtonComponent>
          ) : (
            <FormButtonComponent
              ButtonType="button"
              className={classNames(
                "text-body_font_two  cursor-pointer w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one  "
              )}
              ButtonValue="Confirm"
            ></FormButtonComponent>
          )}
        </div>
        <div></div>
      </div>
    </form>
  );
}

export default PersonalDetailsVerification;
