import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import CollaboratorDetailsView from "./CollaboratorDetailsView";
import NotesModal from "./NotesModal";
import ProfilePicture from "../../../../components/ProfilePicture";
import useCollabActions from "../../../../actions/useCollabActions";
import { Skeleton } from "@mui/material";

function CollaboratorList() {
  const [expandedRow, setExpandedRow] = useState(-1);
  const [isOpenNotes, setNotesModal] = useState(false);
  const [loading, setLoading] = React.useState(true);

  const [data, setData] = useState([]);
  const { getExitingCollabs } = useCollabActions();
  const getData = () => {
    setLoading(true);

    getExitingCollabs()
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setData(res.data);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getData();
  }, []);
  if (loading) {
    return (
      <div className="bg-white w-full ease-in duration-300  rounded shadow-lg">
        <div className="text-subtitle_one px-4 py-4">Collaborator</div>
        <div className="relative overflow-x-auto max-md:max-w-80 px-10">
          <Skeleton width={"100%"} height={70} />
          <Skeleton width={"100%"} height={70} />
          <Skeleton width={"100%"} height={70} />
        </div>
      </div>
    );
  }
  return (
    <div className="bg-white w-full ease-in duration-300  rounded shadow-lg">
      <NotesModal isOpen={isOpenNotes} onClose={() => setNotesModal(false)} />
      <div className="text-subtitle_one px-4 py-4">Collaborator</div>
      <div className="relative overflow-x-auto max-md:max-w-80">
        <table className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Collaborator
              </th>
              <th scope="col" className="px-6 py-3">
                Notes
              </th>
              <th scope="col" className="px-6 py-3">
                Message
              </th>
              <th scope="col" className="px-6 py-3">
                <ToggleButton />
              </th>
            </tr>
          </thead>
          <tbody className="relative">
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <tr
                  className={classNames(
                    { "border-b": index !== expandedRow },
                    "bg-white dark:bg-gray-800 dark:border-gray-700"
                  )}
                >
                  <td className="px-6 py-4">March 19, 2024</td>
                  <td className="px-6 py-4 flex items-center gap-2">
                    <ProfilePicture />
                    Florine Marquardt
                  </td>
                  <td
                    onClick={() => setNotesModal(true)}
                    className="px-10 py-4 cursor-pointer"
                  >
                    <img
                      src={"/assets/icons/provider/notes.png"}
                      className="w-4 h-4 mr-5"
                    />
                  </td>
                  <td className="px-10 py-4  cursor-pointer">
                    <img
                      src={"/assets/icons/provider/new-email.png"}
                      className="w-4 h-4 mr-5"
                    />
                  </td>
                  <td className="px-6 py-4 text-mid_tone_three ">
                    <span className="cursor-not-allowed">Active</span>
                  </td>
                  <td
                    className="px-6 py-4 cursor-pointer"
                    onClick={() =>
                      expandedRow === index
                        ? setExpandedRow(-1)
                        : setExpandedRow(index)
                    }
                  >
                    <FontAwesomeIcon
                      icon={expandedRow === index ? faChevronUp : faChevronDown}
                      className="h-4 w-4 text-gray-400  rounded-full"
                      aria-hidden="true"
                    />
                  </td>
                </tr>
                {expandedRow === index && (
                  <tr className="border-b">
                    <td colSpan="6">
                      <CollaboratorDetailsView />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
const ToggleButton = () => (
  <label className="inline-flex items-center me-5 cursor-pointer">
    <input type="checkbox" value="" className="sr-only peer" />
    <div className="relative w-11 h-6 bg-complementary_accent_three rounded-full peer peer-focus:ring-4 peer-focus:bg-gray_out_text dark:peer-focus:bg-complementary_accent_three dark:bg-complementary_accent_three peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-gray_out after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray_out_text peer-checked:bg-mid_tone_three"></div>
  </label>
);

export default CollaboratorList;
