import React from "react";
import PersonalDetailsForm from "./PersonalDetailsForm";

function PatientDetailsForm({
  iAmGuardianOf,
  checkIsValid,
  handlePersonalDetails,
  setIsEditingPersonalDetails,
  isEditingPersonalDetails,
  setUser,
  handleChange,
  userDetails,
}) {
  return (
    <section className="my-4" aria-label="details-from" id="details-from">
      <div className="text-2xl text-color-1 font-semibold ">
        {iAmGuardianOf ? "Guardians" : "Patients"} Details
      </div>
      {userDetails ? (
        <PersonalDetailsForm
          checkFormValidityCheck={checkIsValid}
          onSubmit={handlePersonalDetails}
          setIsEditing={setIsEditingPersonalDetails}
          isEditing={isEditingPersonalDetails}
          setUser={setUser}
          handleChange={handleChange}
          userDetails={userDetails}
        />
      ) : null}
    </section>
  );
}

export default PatientDetailsForm;
