import React, { useContext, useEffect, useState } from "react";
import useUserActions from "../../actions/useUserActions";
import { AuthContext } from "../../App";
import SelfDiagnosis from "./Selfdiagnosis";
import PlanAndPayments from "./PlanAndPayments";
import BillingDetails from "./BillingDetails";
import PatientDetailsForm from "./PatientDetailsForm";
import WhoIm from "./WhoIm";
import FormButtonComponent from "../../containers/forms/registration-and-verification/FormButtonComponent";

function DetailsForm() {
  const [guardianOf, setGuardianOf] = React.useState("");
  const [iAmGuardianOf, setIAmGuardianOf] = React.useState(false);
  const [iAmPatient, setIAmPatient] = React.useState(false);

  const handleChangeGuardianOf = (event) => {
    setGuardianOf(event.target.value);
  };

  const { update: updatePersonalDetails, getUser } = useUserActions();

  const checkIsValid = () => {
    setValid(true);
  };
  const [updates, setUpdates] = useState({});

  const handlePersonalDetails = async (data) => {
    const response = await updatePersonalDetails({ ...data, ...updates });
    const user = await getUser();

    setIsEditingPersonalDetails(false);
    setUser(user?.data?.user);
  };

  const [isEditingPersonalDetails, setIsEditingPersonalDetails] =
    useState(false);
  const { user, updateUserContext, iSMd } = useContext(AuthContext);

  const [userDetails, setUser] = useState(user);

  const handleChange = (name, value) => {
    setUpdates({ ...updates, [name]: value });
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await getUser();

      setUser(user.data.user);
    };
    getUserInfo();
  }, []);

  return (
    <div className="bg-gray_out p-10 text-dark_tone flex flex-col gap-4 max-md:p-2">
      <WhoIm
        iAmGuardianOf={iAmGuardianOf}
        handleChangeGuardianOf={handleChangeGuardianOf}
        setIAmGuardianOf={setIAmGuardianOf}
        iAmPatient={iAmPatient}
        setIAmPatient={setIAmPatient}
        guardianOf={guardianOf}
      />
      <PatientDetailsForm
        iAmGuardianOf={iAmGuardianOf}
        checkIsValid={checkIsValid}
        handlePersonalDetails={handlePersonalDetails}
        setIsEditingPersonalDetails={setIsEditingPersonalDetails}
        isEditingPersonalDetails={isEditingPersonalDetails}
        setUser={setUser}
        handleChange={handleChange}
        userDetails={userDetails}
      />
      <SelfDiagnosis />
      <PlanAndPayments />
      <BillingDetails />
      <div className="mt-2 flex gap-3 max-md:px-2 ">
        <FormButtonComponent
          ButtonType="button"
          // isDisabled={isSaving}
          className="text-body_font_two  w-36  hover:border-transparent  border rounded-md h-11 text-light_tone_one bg-mid_tone_two submit-btn-bg-color"
          ButtonValue={"Schedule"}
        ></FormButtonComponent>
      </div>
    </div>
  );
}

export default DetailsForm;
