import React from "react";
import NPIFrom from "./NPIFrom";
import SSNForm from "./SSNForm";

function NPIVerification() {
  return (
    <div className="py-10  max-md:px-10 ">
      <div className="text-2xl ">NPI Verification</div>
      <div className="text-2xl max-md:px-10 mb-4 "></div>
      <div className="bg-complementary_accent_one p-10 max-md:py-6 max-md:px-1 rounded-2xl shadow-lg">
        <NPIFrom />
        {/* <SSNForm /> */}
      </div>
    </div>
  );
}

export default NPIVerification;
