import React from "react";
import ImageUploading from "react-images-uploading";

export function ProfilePictureUploader({ onImageChange, picture }) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setImages(imageList);
    onImageChange(imageList);
  };

  return (
    <div className="profilePictureUploader">
      <ImageUploading
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <button
              style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              A Profile Picture helps personalize your Account{" "}
              <span className="px-2 rounded   text-mid_tone_two">
                {picture
                  ? "( Click here to Update )"
                  : "( Click here to upload )"}
              </span>
            </button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img
                  src={image["data_url"]}
                  alt=""
                  width="100"
                  height="100"
                  className="rounded-full"
                />
                <div className="image-item__btn-wrapper flex gap-2">
                  <button
                    onClick={() => {
                      console.log("delting--index");
                      onImageRemove(index);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
