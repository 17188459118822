export const cityNames = [
  { id: 1, name: "New York,New York" },
  { id: 2, name: "Los Angeles,California" },
  { id: 3, name: "Chicago,Illinois" },
  { id: 4, name: "Houston,Texas" },
  { id: 5, name: "Philadelphia,Pennsylv" },
  { id: 6, name: "Phoenix,Arizona" },
  { id: 7, name: "San Antonio,Texas" },
  { id: 8, name: "San Diego,California," },
  { id: 9, name: "Dallas,Texas" },
  { id: 10, name: "San Jose,California," },
];


export const formatResult = (item) => {
    return (
      <>
        <span id="auto-list" style={{ display: 'block', textAlign: 'left' }}>name: {item.name}</span>
      </>
    )
  }

