import React from "react";

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <div className="flex border-b ">
     
      &nbsp;
      {["Primary", "Updates", "Social"].map((tab) => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`py-3 px-4  ${
            activeTab === tab
              ? "border-b-2 submit-btn-bg-color text-light_tone_one rounded-xl "
              : "border-transparent"
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
