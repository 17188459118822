import React from "react";

function FormButtonComponent({
  ButtonValue,
  ButtonType,
  className,
  isDisabled,
  onClick,
  children,
}) {
  return (
    <div className="mt-2">
      <button
        type={ButtonType}
        disabled={isDisabled}
        className={` ${className} `}
        value={ButtonValue}
        onClick={onClick}
      >
        {ButtonValue} {children}
      </button>
    </div>
  );
}

export default FormButtonComponent;
