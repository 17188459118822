import React from "react";

function HomePageSectionFour() {
  return (
    <div className="self-stretch mt-36 w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
        <div className="flex flex-col w-[43%] max-md:ml-0 max-md:w-full">
          <img
            alt=""
            loading="lazy"
            src="/assets/images/platform-advantage-001.png"
            className="w-full  max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col   w-[57%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col h-[70%] one-platform-text-box px-14  py-7 mb-10  mt-24 w-full text-xl font-bold text-black bg-white rounded-3xl max-md:px-5 max-md:mt-10 max-md:max-w-full ">
            <div className="mt-10 text-3xl tracking-wider uppercase max-md:max-w-full">
              One platform. <br />
              Many advantages.
            </div>
            <div
              className="mt-10 font-light  leading-7 max-md:max-w-full
            
            "
            >
              At Cure Culture, you can focus on taking care of your patients. We
              will take care of all your concerns Our fully automated platform
              is designed to take away all the hassles of an online practice.
            </div>
            <div className="justify-center mt-10 self-start px-14 py-7 text-white capitalize bg-blue-900 rounded-xl max-md:px-5 max-md:mt-10">
              Learn More
            </div>
            <br/> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePageSectionFour;
