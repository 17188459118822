export const baseURL = process.env.REACT_APP_BASE_URL;
const auth_token = localStorage.getItem("auth-token");

export const headers = {
  headers: {
    Authorization: `Bearer ${auth_token}`,
  },
};

export { headers as AUTH_HEADER };
