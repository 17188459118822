import React from "react";
import Footer from "../main/components/Footer";
import Header from "./Header";

function ErrorPageNotFound() {
  return (
    <div>
      <Header isPatent={true} />
      <div className="flex justify-center flex-col align-middle items-center pt-20 ">
        <h1 className="text-5xl">We are working on it</h1>
        <br />
        <h3 className="text-body_font_one">This Page will come soon</h3>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ErrorPageNotFound;
