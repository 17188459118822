import React, { useEffect, useState } from "react";
import MdProfileSection from "./MdProfileSection";
import useProviderActions from "../../../../../../actions/useProviderActions";
import CSA from "./CSA";
import { Pending } from "@mui/icons-material";

function MainMD({ setIsEditing }) {
  const { getControlledSubstanceStatus } = useProviderActions();
  const [showCsa, setCSA] = useState(false);

  const [controlledSubstance, setControlledSubstance] = useState({
    download_url: "",
    status: "pending",
    is_active: 0,
    valid_till: null,
    enabled: 0,
  });
  const getData = () => {
    getControlledSubstanceStatus().then((res) => {
      setControlledSubstance(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-full px-10 text-body_font_three max-md:p-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg">
        <div className="px-2 flex justify-between">
          <span className="text-xxl font-semibold ">Controlled Substance</span>
          <span>
            <label className="inline-flex items-center me-5 cursor-pointer">
              <input
                defaultChecked={controlledSubstance.is_active == 1}
                disabled={controlledSubstance.is_active == 1}
                type="checkbox"
                value=""
                onChange={(e) => {
                  setCSA(e.target.checked);
                }}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-complementary_accent_three rounded-full peer peer-focus:ring-4 peer-focus:bg-gray_out_text dark:peer-focus:bg-complementary_accent_three dark:bg-complementary_accent_three peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-gray_out after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray_out_text peer-checked:bg-mid_tone_three"></div>
            </label>
          </span>
        </div>
        <div className="mt-5  px-2 flex justify-between">
          <span>
            {controlledSubstance.is_active == 1 ? "DEA available on File" : " "}{" "}
          </span>
          {controlledSubstance.valid_till ? (
            <span>Valid Till - {controlledSubstance.valid_till}</span>
          ) : null}
          <span className="flex items-center gap-2">
            {controlledSubstance.status === "pending" ? (
              <Pending />
            ) : (
              <img
                src="/assets/images/verified-green-tick.png"
                className="h-5 w-5"
              />
            )}
            {controlledSubstance.status.toLocaleUpperCase()}
          </span>
        </div>
        {showCsa && controlledSubstance.is_active == 0 ? (
          <CSA downloadUrl={controlledSubstance.download_url} />
        ) : null}
      </section>
      <br />
      <MdProfileSection setIsEditing={setIsEditing} />
    </div>
  );
}

export default MainMD;
