import "./App.css";
import { RouterProvider } from "react-router-dom";
import {
  patientRoutes,
  providerRoutes,
  unAuthorizedRoutes,
} from "./routers/router";
import { useEffect, useState } from "react";
import { createContext } from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AuthContext = createContext(null);
function App() {
  const [authToken, setAuthToken] = useState(false);
  const [loginBy, setLoginBy] = useState(false);
  const [isAuth, setAuth] = useState(false);
  const [iSMdDo, setProviderType] = useState(false);

  const [userDetails, setUserDetails] = useState(null);
  const [routes, setRoute] = useState(unAuthorizedRoutes);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.provider_details &&
      userDetails.provider_details.is_md_app
    ) {
      if (userDetails.provider_details.is_md_app === "MD" && iSMdDo === false) {
        setProviderType(true);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    setLoginBy(localStorage.getItem("login-by"));
    setAuthToken(localStorage.getItem("auth-token"));
  }, []);
  const updateUserContext = (user) => {
    const newUser = { ...user };
    setUserDetails(newUser);
  };
  useEffect(() => {
    if (isAuth) {
      if (userDetails) {
        if (userDetails.usertype === "provider") {
          setRoute(providerRoutes);
        } else if (userDetails.usertype === "patient") {
          setRoute(patientRoutes);
        }
      }
    } else {
      setRoute(unAuthorizedRoutes);
    }
  }, [isAuth, userDetails]);

  const userDetailsStorage = localStorage.getItem("user-details");
  useEffect(() => {
    if (authToken) {
      if (userDetailsStorage) {
        const user = JSON.parse(userDetailsStorage);
        updateUserContext(user);
        setAuth(true);
      }
    }
  }, [authToken]);

  return (
    <div className="App md:overflow-x-hidden max-md:-mx-1 body_font_three">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthContext.Provider
        value={{
          isAuth,
          setAuth,
          userDetails,
          iSMd: iSMdDo,
          updateUserContext,
        }}
      >
        <RouterProvider router={routes} />
      </AuthContext.Provider>
    </div>
  );
}

export default App;
