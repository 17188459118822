import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import MainContent from "./components/MainContent";
import MainContentMobile from "./components/MainContentMobile";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Compose from "./components/Compose";
import { uniqueId } from "lodash";
import cn from "classnames";
import { Drawer } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import { CreateOutlined, MenuOpenOutlined } from "@mui/icons-material";

const Messaging = () => {
  // if (width < 768) {
  const [width] = useWindowDimensions();
  const [isComposing, setComposing] = useState(false);
  return (
    <div className="flex h-screen mt-2">
      {width > 768 ? (
        <Sidebar setComposing={setComposing} />
      ) : (
        <MessageSideBar setComposing={setComposing} />
      )}
      <div className="flex-1 flex flex-col relative">
        {width > 768 ? (
          <MainContent />
        ) : (
          <div className="mb-20">
            <MainContentMobile />
          </div>
        )}
      </div>
      {isComposing ? <Compose onClose={setComposing} /> : null}
    </div>
  );
};

export default Messaging;

const MessageSideBar = ({ setComposing }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const message_CatList = [
    {
      name: "Inbox",
      id: 1,
      icon: "",
      link: "/provider/profile-management",
      pageName: "profile-management",
    },
  ];
  return (
    <>
      <div className="absolute z-20 right-10 top-24 flex gap-4">
        <CreateOutlined onClick={() => setComposing(true)} />
        <MenuOpenOutlined onClick={() => setIsNavOpen((prev) => !prev)} />
      </div>
      <Drawer open={isNavOpen} onClose={() => setIsNavOpen(false)}>
        <Box
          key={uniqueId()}
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          <nav aria-label="main mailbox folders" className="text-dark_tone">
            <List onClick={() => setIsNavOpen(false)}>
              <ListItem>
                <ListItemButton onClick={() => setComposing(true)}>
                  <img
                    src="/assets/icons/provider/compose-email-btn.png"
                    width={138}
                    height={48}
                  ></img>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <InboxIcon />
                  </ListItemIcon>
                  <span className="text-dark_tone">Inbox</span>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Drafts" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
          <Divider />
          <nav
            aria-label="secondary mailbox folders"
            className="text-dark_tone"
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText primary="Trash" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton component="a" href="#simple-list">
                  <ListItemText primary="Spam" />
                </ListItemButton>
              </ListItem>
            </List>
          </nav>
        </Box>
      </Drawer>
    </>
  );
};
