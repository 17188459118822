import React, { useState } from "react";
import ModalComponent from "./ModalComponent";
import classNames from "classnames";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { maskInput } from "./utils";

function AddAdditionalPhoneModal({ isOpen, onClose, submit }) {
  const [isPrimary, setPrimary] = useState(false);
  const [value, setValue] = useState("");
  const handleSubmit = () => {
    submit({ isPrimary, value, type: "phone" });
  };
  const body = (
    <div className="p-10  flex justify-center gap-5 flex-col items-center">
      <img src="/assets/icons/provider/verification-icon.png" />
      <div className="text-2xl font-bold">Add Additional Phone Number</div>
      <input
        onChange={(e) => setValue(e.target.value)}
        className={classNames(
          "block flex-1 border-2  rounded-md my-2  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 w-full"
        )}
        placeholder={"Add Phone Number"}
      />
      <FormControlLabel
        control={<Checkbox onChange={(e) => setPrimary(e.target.checked)} />}
        label="Set as Primary"
      />
      <div className="flex gap-3">
        <button
          className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-48 max-md:w-full
  "
          onClick={handleSubmit}
        >
          Submit
        </button>

        <button
          className="bg-complementary_accent_three btn  py-3 px-5 rounded-xl w-48 max-md:w-full
        "
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
    </div>
  );
  return <ModalComponent isOpen={isOpen} body={body}></ModalComponent>;
}

export default AddAdditionalPhoneModal;
