import { Cancel, Delete } from "@mui/icons-material";
import { Button } from "@mui/material";
import _, { uniqueId } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";

const AddSlotComponent = ({ handleAdd, type, cancel }) => {
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const add = () => {
    if (start && end) {
      const payload = {
        id: uniqueId(),
        slot_start: start,
        slot_end: end,
        type,
      };
      handleAdd(payload);
    }
  };
  return (
    <div className="bg-gray_out p-2 rounded-md text-gray_out_text my-2">
      <div className="my-4 flex justify-between items-center max-md:gap-2 max-md:flex-col">
        <span className="font-bold text-body_font_three text-dark_tone">
          New Slot
        </span>
        <span>From</span>
        <input
          type="time"
          className="w-96 border p-3 rounded-md  max-md:w-80"
          onChange={(e) => setStart(e.target.value)}
        />

        <span>To</span>
        <input
          type="time"
          onChange={(e) => setEnd(e.target.value)}
          className="w-96 border p-3 rounded-md  max-md:w-80"
        />
        <Button disabled={!start || !end} onClick={add} variant="outlined">
          Add
        </Button>

        <Button onClick={cancel} color="error">
          <Cancel />
        </Button>
      </div>
    </div>
  );
};

const SlotComponent = ({
  id,
  type,
  slot_start,
  slot_end,
  handleDelete,
  idx,
}) => {
  return (
    <div
      key={id + uniqueId}
      className="bg-gray_out p-2 rounded-md text-gray_out_text my-2"
    >
      <div className="my-4 flex justify-between items-center max-md:gap-2 max-md:flex-col">
        <span className="font-bold text-body_font_three text-dark_tone">
          Slot {idx + 1}
        </span>
        <span>From</span>
        <input
          type="time"
          className="w-96 border p-3 rounded-md  max-md:w-80"
          readOnly
          defaultValue={slot_start}
        />

        <span>To</span>
        <input
          type="time"
          className="w-96 border p-3 rounded-md  max-md:w-80"
          readOnly
          defaultValue={slot_end}
        />
        <Button
          onClick={() => handleDelete(id, type, slot_start)}
          color="error"
        >
          <Delete />
        </Button>
      </div>
    </div>
  );
};

function ConsultationHoursForPatient() {
  const [weekendSlots, setWeekendSlots] = useState([]);
  const [weekdaySlots, setWeekdaySlots] = useState([]);
  const [isAddingWeekDaySlot, setWeekDaySlot] = useState(false);
  const [isAddingWeekendSlot, setWeekendSlot] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const handleAdd = (newSlot) => {
    setIsEdited(true);
    if (newSlot.type === "weekend") {
      setWeekendSlots([...weekendSlots, newSlot]);
    }
    if (newSlot.type === "weekday") {
      setWeekdaySlots([...weekdaySlots, newSlot]);
    }

    setWeekendSlot(false);
    setWeekDaySlot(false);
  };

  const handleDelete = (id, type, slot_start) => {
    setIsEdited(true);

    if (type === "weekday") {
      const slots = [...weekdaySlots];
      _.remove(slots, (x) => x.id == id);
      setWeekdaySlots(slots);
    } else {
      const slots = [...weekendSlots];
      _.remove(slots, (x) => x.id == id);
      setWeekendSlots(slots);
    }
  };

  const handleSlotUpdate = (data) => {
    const weekends = [];
    const weekdays = [];
    data.forEach((x) => {
      if (x.type == "weekday") {
        weekdays.push(x);
      } else {
        weekends.push(x);
      }
    });
    setWeekdaySlots(weekdays);
    setWeekendSlots(weekends);
  };

  const { getConsultationHours, saveConsultationHours } = useProviderActions();
  const { userDetails } = useContext(AuthContext);
  const getData = () => {
    getConsultationHours(userDetails.user_id).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          handleSlotUpdate(res.data);
        }
      }
    });
  };
  useEffect(() => {
    if (userDetails && userDetails.user_id) getData();
  }, []);
  const [saving, setSaving] = useState(false);
  const handleSave = () => {
    setSaving(true);
    const payload = [...weekdaySlots, ...weekendSlots];

    saveConsultationHours({
      consultation_hours: payload
        .map(function (item, idx) {
          return { ...item, sequence: idx };
        })
        .map(({ id, type, sequence, slot_start, slot_end }) => ({
          id,
          type,
          sequence,
          slot_start,
          slot_end,
        })),
    })
      .then((x) => {
        setWeekdaySlots([]);
        setWeekendSlots([]);
        setIsEdited(false);
        getData();
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <div className="my-4  px-10 max-md:px-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Consultation Hours for Patient
        </div>
        <div>
          <span>
            <span className="text-body_font_two">
              Weekdays (Monday To Friday) &nbsp;
            </span>
            <span className="text-gray_out_text text-body_font_three">
              Share your typical availability, but you can consult outside these
              hours.
            </span>
            {!isAddingWeekDaySlot ? (
              <img
                className="cursor-pointer"
                onClick={() => {
                  setWeekDaySlot(true);
                  setWeekendSlot(false);
                }}
                src="/assets/icons/provider/add-icon.png"
              ></img>
            ) : null}
          </span>
        </div>
        {/* Add here*/}

        {isAddingWeekDaySlot ? (
          <AddSlotComponent
            type={"weekday"}
            cancel={() => setWeekDaySlot(false)}
            handleAdd={handleAdd}
          />
        ) : null}
        {weekdaySlots && weekdaySlots.length
          ? weekdaySlots.map((slot, idx) => {
              return (
                <SlotComponent
                  {...slot}
                  idx={idx}
                  handleDelete={handleDelete}
                />
              );
            })
          : null}
        <div className="mt-3">
          <span className="text-body_font_two">Weekend (Saturday)&nbsp;</span>
        </div>

        {!isAddingWeekendSlot ? (
          <img
            className="cursor-pointer"
            onClick={() => {
              setWeekendSlot(true);
              setWeekDaySlot(false);
            }}
            src="/assets/icons/provider/add-icon.png"
          ></img>
        ) : null}
        {isAddingWeekendSlot ? (
          <AddSlotComponent
            cancel={() => setWeekendSlot(false)}
            type={"weekend"}
            handleAdd={handleAdd}
          />
        ) : null}

        {weekendSlots && weekendSlots.length
          ? weekendSlots.map((slot, idx) => {
              return (
                <SlotComponent
                  idx={idx}
                  {...slot}
                  handleDelete={handleDelete}
                />
              );
            })
          : null}

        {isEdited ? (
          <button
            onClick={handleSave}
            disable={saving}
            className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg my-2"
          >
            {saving ? "Saving..." : "Save"}
          </button>
        ) : null}
      </section>
    </div>
  );
}

export default ConsultationHoursForPatient;
