import React, { useContext, useEffect, useState } from "react";

import FormInputComponent from "./FormInputComponent";
import { useForm } from "react-hook-form";
import FormButtonComponent from "./FormButtonComponent";
import classNames from "classnames";
import useProviderActions from "../../../actions/useProviderActions";
import { AuthContext } from "../../../App";
import ClipLoader from "react-spinners/ClipLoader";
import { CSSProperties } from "react";
import ModalComponent from "../../../components/modals/ModalComponent";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function NPIFrom() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(3);
  const [value, setValue] = useState("");
  const { userDetails } = useContext(AuthContext);
  const [showVerificationFailedPopUP, setShowVerificationFailedPopUP] =
    useState(false);
  const [showVerificationFailedPopUP1, setShowVerificationFailedPopUP1] =
    useState(false);
  useEffect(() => {
    if (
      userDetails &&
      userDetails.provider_details &&
      userDetails.provider_details.npi_remaining_attempts
    ) {
      setRemainingAttempts(userDetails.provider_details.npi_remaining_attempts);
    }
  }, [userDetails]);
  const { verifyNPI } = useProviderActions();
  const handleNPISubmit = async (data) => {
    setIsSubmitting(true);
    const response = await verifyNPI({
      ...data,
    });

    if (response && response.data && response.data.remaining_attempts > -1) {
      setRemainingAttempts(response.data.remaining_attempts);
      if (response.status === "failed") {
        if (response.data.remaining_attempts === 0) {
          setShowVerificationFailedPopUP(true);
        } else {
          setShowVerificationFailedPopUP1(true);
        }
      }
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <ModalComponent
        isOpen={showVerificationFailedPopUP1}
        className={
          "w-2/3 absolute bg-complementary_accent_one  rounded-lg p-10 shadow-xl"
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">NPI Verification Unsuccessful</div>
            <div className="text-subtitle_one w-2/3 text-center">
              We've detected discrepancies in the provided details. Possible
              issues include incorrect NPI information, or mismatches in the
              associated name.
            </div>
            <button
              className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-72
          "
              onClick={() => {
                setShowVerificationFailedPopUP1(false);
              }}
            >
              Please try again
            </button>
          </div>
        }
      />
      <ModalComponent
        isOpen={showVerificationFailedPopUP}
        className={
          "w-2/3 absolute bg-complementary_accent_one  rounded-lg p-10 shadow-xl"
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">NPI Verification Failed</div>
            <div className="text-subtitle_one w-2/3 text-center">
              Despite multiple attempts, we're unable to verify your details and
              cannot proceed further. Kindly provide your email or phone number
              for our admin to contact you.
            </div>

            <div className="flex gap-3">
              <button
                className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-44
          "
                onClick={() => {
                  setShowVerificationFailedPopUP(false);
                }}
              >
                Go back to home
              </button>
            </div>
          </div>
        }
      />

      <form className="  max-md:p-0 " onSubmit={handleSubmit(handleNPISubmit)}>
        <div className="flex gap-3 items-baseline  max-md:p-2 max-md:flex-col max-md:items-start">
          <FormInputComponent
            register={register}
            inputFor={"npino"}
            labelClasses="text-body_font_two mr-10"
            inputName="npino"
            inputLabel={"National Provider Identifier"}
            inputType="text"
            className={classNames(
              {
                "border-error  ": errors.npino,
              },
              `flex-1 border-2  rounded-xl  bg-transparent  w-[489px] max-md:w-72
         text-gray-900 placeholder:text-gray-400 h-12 focus:ring-0 sm:text-sm 
         sm:leading-6  py-5 pl-2  `
            )}
            inputId="npino"
            inputAutocomplete="npino"
            validations={{
              required: { value: true, message: "NPI required" },
              // minLength: { value: 10, message: "Invalid NPI" },
              maxLength: { value: 10, message: "Invalid NPI" },
            }}
            inputPlaceholder=""
            containerClasses="flex items-baseline gap-3 max-md:flex-col "
            errors={errors.npino}
            handleChange={(e) => setValue(e.target.value)}
          />

          <div className="flex items-center ">
            <FormButtonComponent
              ButtonType="submit"
              isDisabled={
                value.length !== 10 || remainingAttempts <= 0 || isSubmitting
              }
              className={classNames(
                {
                  "submit-btn-bg-color": value.length === 10,
                },
                `text-body_font_two  w-36  hover:border-transparent  border 
        rounded-md h-11 text-light_tone_one bg-mid_tone_two `
              )}
              ButtonValue={isSubmitting ? "Verifying" : "Submit"}
            >
              <ClipLoader
                color={"#ffffff"}
                loading={isSubmitting}
                size={20}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </FormButtonComponent>
            {userDetails &&
            userDetails.provider_details &&
            userDetails.provider_details.npi_verification_status ===
              "success" ? (
              <>
                <img
                  alt=""
                  loading="lazy"
                  src="/assets/images/verified-green-tick.png"
                  className="ml-3 "
                />
                <span>Verified</span>
              </>
            ) : (
              <>
                <img
                  alt=""
                  loading="lazy"
                  src="/assets/images/verified-red-cross.png"
                  className="ml-3 "
                />
                <span>Not Verified</span>
              </>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default NPIFrom;
