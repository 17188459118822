import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { months } from "./utils";
import TimeSlotPicker from "./TimeSlotPicker";
import "./Appointment.css";
import useWindowDimensions from "../../utils/useWindowDimensions";
import useProviderActions from "../../../actions/useProviderActions";
const AppointmentPicker = ({ checkIsValid }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedSelectedDate, setFormattedDate] = useState("");

  const [slotsData, setSlotsData] = useState(null);
  const { getAvailableSlots } = useProviderActions();
  const updateSlots = async () => {
    const response = await getAvailableSlots();
    if (response?.data?.status === "success") {
      setSlotsData(response.data.data);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const response = await getAvailableSlots();
      if (response?.data?.status === "success") {
        setSlotsData(response.data.data);
      }
    };
    fetchData();
  }, []);
  // const [viewMode, setViewMode] = useState("monthly");
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const newDate = new Date();
  const currentYear = newDate.getFullYear();
  const [date, setDate] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());

  const currentMonth = newDate.getMonth();

  const allowedMonths =
    currentMonth === 12
      ? [
          { label: `December ${currentYear}`, value: 12 },
          { label: `January ${currentYear + 1}`, value: 1 },
        ]
      : months.slice(currentMonth, currentMonth + 2);
  const CustomHeader = ({ changeMonth }) => {
    return (
      <Select
        classNames={{
          control: () =>
            classNames(
              {},
              "w-72 mb-10 mt-4 h-10 rounded-xl select-box-upload-doc"
            ),
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        defaultValue={{
          label: months[currentMonth].label,
          value: months[currentMonth].value,
        }}
        onChange={(e) => changeMonth(e.value - 1)}
        options={allowedMonths}
      />
    );
  };

  const formatDay = (locale) => {
    return locale;
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    const allowedDays = slotsData.working_days.split(",").map((n) => Number(n));
    return allowedDays.includes(day);
  };

  const nextFourWeeks = new Date(newDate.setDate(newDate.getDate() + 28));
  const [width] = useWindowDimensions();

  useEffect(() => {
    const getFormattedDate = () => {
      if (selectedDate) {
        const dateToConvert = new Date(selectedDate);
        dateToConvert.setDate(dateToConvert.getDate() + 1);

        const convertedDate = dateToConvert.toISOString().split("T")[0];
        setFormattedDate(convertedDate);
      }
    };

    getFormattedDate();
  }, [selectedDate]);

  if (slotsData === null) {
    return null;
  }
  if (slotsData?.my_time_slots?.length >= 3) {
    checkIsValid();
  }
  return (
    <div className="bg-complementary_accent_one p-4 shadow-lg mt-10">
      <div id="appointmentpicker" className="">
        {width < 768 ? (
          <DatePicker
            startDate={Date.now()}
            onChange={handleDateChange}
            inline
            showLeadingZeros={true}
            value={date}
            filterDate={isWeekday}
            minDate={new Date()}
            maxDate={nextFourWeeks}
            dateFormat="MMMM, yyyy"
            renderCustomHeader={(props) => <CustomHeader {...props} />}
          />
        ) : (
          <div className="flex w-full">
            <DatePicker
              startDate={Date.now()}
              onChange={handleDateChange}
              inline
              showLeadingZeros={true}
              value={date}
              filterDate={isWeekday}
              minDate={new Date()}
              maxDate={nextFourWeeks}
              formatWeekDay={formatDay}
              dateFormat="MMMM, yyyy"
              renderCustomHeader={(props) => <CustomHeader {...props} />}
            />
          </div>
        )}
      </div>
      <div>
        <TimeSlotPicker
          date={selectedDate}
          data={{
            bookedSlots: slotsData.time_slots.find(
              (x) => x.date === formattedSelectedDate
            ),
            startTime: slotsData.time_start,
            endTime: slotsData.time_end,
            slotDuration: slotsData.slot_duration,
          }}
          formattedSelectedDate={formattedSelectedDate}
          mySlots={slotsData.my_time_slots}
          updateSlots={updateSlots}
          checkIsValid={checkIsValid}
        />
      </div>
    </div>
  );
};

export default AppointmentPicker;
