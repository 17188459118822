import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import classNames from "classnames";

function CarouselComponent() {
  const items = [
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },

    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
    { src: "/assets/images/patient-dashboard-img.png" },
  ];

  return (
    <>
      <Carousel
        showArrows={true}
        swipeable={true}
        // transitionTime={5000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        showIndicators={true}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <img
            src="/assets/images/active-carousel-index.png"
            height={7}
            className={classNames({
              "active-carousel-index": isSelected,
              "in-active-carousel-index": !isSelected,
            })}
          />
        )}
        stopOnHover={false}
        className="lg:w-full "
      >
        {items.map((item, idx) => (
          <div key={idx}>
            <img
              width={item.width ? item.width : 1000}
              height={item.height ? item.height : 500}
              src={item.src}
            />
          </div>
        ))}
      </Carousel>
    </>
  );
}

export default CarouselComponent;
