import moment from "moment";
import React from "react";

function AppExperienceAndConsultationHours({
  consultationHours,
  totalExperience,
}) {
  const weekdays = consultationHours.filter((x) => x.type == "weekday");
  const weekends = consultationHours.filter((x) => x.type == "weekend");

  return (
    <section className="p-10 max-md:p-4">
      <div className="w-full flex justify-between max-md:flex-col">
        <div className="w-1/2 text-body_font_two font-medium pr-16 max-md:w-full max-md:pr-0 max-md:flex max-md:items-center">
          <span className="text-color-1">Total Experience:</span>
          <div className="pr-16 flex gap-2 text-body_font_three mt-4 max-md:mt-1">
            <span>{totalExperience} years</span>
          </div>
        </div>
        <div className="w-1/2 text-body_font_two font-medium pl-16 max-md:w-full max-md:pl-0">
          <span className="text-color-1"> Consultation Hours</span>
          <div className="pr-16 flex  text-body_font_three mt-4 flex-col">
            <span className="font-semibold">Weekdays</span>
            {weekdays.map((slot, key) => {
              const momentObjStart = moment(slot.slot_start, "HH:mm:ss");
              const timeString12Start = momentObjStart.format("hh:mm A");
              const momentObjEnd = moment(slot.slot_end, "HH:mm:ss");
              const timeString12End = momentObjEnd.format("hh:mm A");
              return (
                <span
                  key={key}
                >{`${timeString12Start} - ${timeString12End}`}</span>
              );
            })}
          </div>
          <div className="pr-16 flex text-body_font_three mt-4  flex-col">
            <span className="font-semibold">Saturdays</span>
            {weekends.map((slot, key) => {
              const momentObjStart = moment(slot.slot_start, "HH:mm:ss");
              const timeString12Start = momentObjStart.format("hh:mm A");
              const momentObjEnd = moment(slot.slot_end, "HH:mm:ss");
              const timeString12End = momentObjEnd.format("hh:mm A");
              return (
                <span
                  key={key}
                >{`${timeString12Start} - ${timeString12End}`}</span>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppExperienceAndConsultationHours;
