import React from "react";

function PositionsAndLanguages({ languages, experiences }) {
  return (
    <section className="p-10 max-md:p-3">
      <div className="w-full flex justify-between max-md:flex-col max-md:gap-3">
        <div className="w-1/2 text-body_font_two font-medium pr-16 max-md:w-full max-md:pr-0 max-md:gap-2">
          <span className="text-color-1">Positions Held:</span>
          <div className=" flex gap-1 text-body_font_three mt-4 flex-col max-md:w-full">
            {experiences.map((x) => {
              return (
                <div
                  key={x.id}
                  className="pr-16 flex gap-2 text-body_font_three  items-center "
                >
                  <img
                    alt=""
                    loading="lazy"
                    src="/assets/images/verified-green-tick.png"
                    className="w-5 h-4"
                  />
                  <span>{`${x.designation} ${x.employer_name}`}</span>
                </div>
              );
            })}

            <div className="pr-16 flex gap-2 text-body_font_three  items-center">
              <img
                alt=""
                loading="lazy"
                src="/assets/images/verified-green-tick.png"
                className="w-5 h-4"
              />
              <span>Private Practice Psychiatry – Cure-Culture</span>
            </div>
          </div>
        </div>
        <div className="w-1/2 text-body_font_two font-medium pl-16 max-md:pl-0 max-md:w-full">
          <span className="text-color-1"> Languages Spoken</span>
          {languages.map((lang) => {
            return (
              <div
                key={lang.id}
                className="pr-16 flex  text-body_font_three mt-4 gap-2 items-center"
              >
                <img
                  alt=""
                  loading="lazy"
                  src="/assets/images/verified-green-tick.png"
                  className="w-5 h-4"
                />
                <span>{lang.language}</span>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default PositionsAndLanguages;
