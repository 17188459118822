import React, { useContext } from "react";

import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../../../App";
import useAuth from "../../../actions/useAuth";

function SignupOne({ heading, subHeading = "", setPageType }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { getGoogleUserDetails } = useAuth();

  const responseFacebook = (response) => {
    // console.log(response);
  };
  const authState = useContext(AuthContext);
  const { userType } = useParams();

  const loginByGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getGoogleUserDetails(tokenResponse.access_token, false, userType);
      authState.setAuth(true);
    },
  });
  const onSubmit = (data) => console.log(data);
  return (
    <>
      <div className="py-6 px-16 max-md:p-2">
        <div className="text-2xl">
          {heading}
          <div className="text-body_font_three flex ">
            {subHeading} &nbsp;
            <div
              onClick={() => setPageType("login")}
              className=" text-blue-600	 cursor-pointer"
            >
              Login
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6  mt-4">
          <button
            onClick={() => loginByGoogle()}
            className="text-button_font p-4 bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/google-login-img.png"></img> Login with
            Google
          </button>

          

          <button className="text-button_font p-4 hover:border-transparent  rounded-md text-light_tone_one border bg-dark_tone flex justify-center gap-3 items-center">
            <img src="/assets/images/apple-login-img.png"></img>
            Login with Apple ID
          </button>
        </div>
        <div className="flex items-baseline">
          <img
            className="w-5/12 h-1 my-10"
            src="/assets/images/login-img-3-1.png"
          ></img>
          <span className="mx-4 text-gray-500">or</span>
          <img
            className="w-5/12 h-1 my-10"
            src="/assets/images/login-img-3-1.png"
          ></img>
        </div>
        <div>
          <div className="mt-2 ">
            <button
              onClick={() => {
                setPageType("register-email");
              }}
              className="text-button_font p-4  hover:border-transparent  border rounded-md w-full text-light_tone_one bg-mid_tone_two submit-btn-bg-color"
            >
              Signup Email
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignupOne;
