import React from "react";

function FormInputComponent({
  inputFor,
  inputLabel,
  inputName,
  inputType,
  inputId,
  inputAutocomplete = "",
  inputPlaceholder,
  register = () => null,
  validations,
  defaultValue,
  className,
  containerClasses = "",
  inputParentClasses = "mt-2",
  isDisabled,
  labelClasses = "block text-sm font-medium leading-6 text-mid_tone_one",
  errors,
  errorsCss,
  inputPattern,
  handleChange,
}) {
  return (
    <div className={containerClasses}>
      <label htmlFor={inputFor} className={labelClasses}>
        {inputLabel}
      </label>
      <div className={inputParentClasses}>
        <input
          type={inputType}
          name={inputName}
          disabled={isDisabled}
          id={inputId}
          autoComplete={inputAutocomplete}
          className={className}
          placeholder={inputPlaceholder}
          {...register(inputName, validations)}
          defaultValue={defaultValue}
          pattern={inputPattern}
          onChange={handleChange}
        />
        {errors && (
          <span className={`text-error ${errorsCss}`}>{errors.message}</span>
        )}
      </div>
    </div>
  );
}

export default FormInputComponent;
