import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ModalComponent from "../../../../../components/modals/ModalComponent";
import useUserActions from "../../../../../actions/useUserActions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

export default function ChangePassword({ onClose, isOpen }) {
  const [currentPassword, setCurrentPassword] = React.useState("");

  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const isDisabled =
    !currentPassword ||
    !confirmPassword ||
    !newPassword ||
    confirmPassword !== newPassword;

  const [showPassword, setShowPassword] = React.useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { changePassword } = useUserActions();
  const handleUpdate = () => {
    if (isDisabled) {
      return null;
    }

    const payload = {
      current_password: currentPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    changePassword(payload);
  };
  const body = (
    <div>
      <div className="flex flex-col">
        <Typography
          className="my-2"
          id="Change Password"
          variant="h5"
          component="h2"
        >
          Change Password
        </Typography>
        <FormControl sx={{ m: 1, mt: 3 }} variant="outlined">
          <InputLabel htmlFor="Current Password">Current Password</InputLabel>
          <OutlinedInput
            className={
              "block flex-1 border-2  rounded-md  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-[#EFF6FF] pl-2 w-full"
            }
            onChange={(e) => setCurrentPassword(e.target.value)}
            placeholder={"Current Password"}
            id="Current Password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="new_password">New Password</InputLabel>
          <OutlinedInput
            className={
              "block flex-1 border-2  rounded-md  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-[#EFF6FF] pl-2 w-full"
            }
            placeholder={"New Password"}
            onChange={(e) => setNewPassword(e.target.value)}
            id={"new_password"}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <FormControl sx={{ m: 1 }} variant="outlined">
          <InputLabel htmlFor="confirm_password">Confirm Password</InputLabel>
          <OutlinedInput
            className={`
              block  
              border-2
                rounded-md
                  bg-transparent
                    text-gray-900
                     placeholder:text-gray-400
                      focus:ring-0
                       sm:text-sm
                        sm:leading-6 
                        bg-[#EFF6FF]
                         pl-2 w-full`}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id={"confirm_password"}
            type={showPassword ? "text" : "password"}
            placeholder={"Confirm Password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  onMouseUp={handleMouseUpPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <div className="flex gap-2">
          <button
            onClick={handleUpdate}
            className={classNames(
              {
                "cursor-not-allowed border": isDisabled,
                "cursor-pointer submit-btn-bg-color": !isDisabled,
              },
              " btn text-light_tone_one py-3 px-5 rounded-xl w-36 max-md:w-full"
            )}
          >
            Update
          </button>
          <button
            onClick={onClose}
            className="bg-complementary_accent_three btn  py-3 px-5 rounded-xl w-36 max-md:w-full
            "
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <ModalComponent isOpen={isOpen} body={body} />
    </div>
  );
}
