import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { fakeMessage } from "./utils";

export default function MainContentMobile() {
  const [checked, setChecked] = React.useState([1]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <List
      dense
      sx={{ width: "100%", maxHeight: "360px", bgcolor: "background.paper" }}
    >
      {fakeMessage.map((message) => {
        const labelId = `checkbox-list-secondary-label-${message.id}`;
        return (
          <ListItem
            key={message.id}
            className="mb-6"
            // secondaryAction={
            //   <Checkbox
            //     edge="end"
            //     onChange={handleToggle(message.id)}
            //     checked={checked.indexOf(message.id) !== -1}
            //     inputProps={{ "aria-labelledby": labelId }}
            //   />
            // }
            disablePadding
          >
            <ListItemButton>
              <ListItemAvatar>
                <Avatar
                  alt={`Avatar n°${message.id + 1}`}
                  src={`/static/images/avatar/${message.id + 1}.jpg`}
                />
              </ListItemAvatar>
              <div className="flex flex-col">
                <div className="flex justify-between w-full">
                  <span className="font-bold">{message.senderName} </span>
                  <span>{message.date} </span>
                </div>
                <div>
                  <ListItemText
                    id={labelId}
                    primary={`${message.subject.split(15)[0]}...`}
                  />
                </div>
              </div>
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
