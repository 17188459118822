import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import classNames from "classnames";
function CarouselComponent() {
  const items = [
    {
      src: "/assets/images/patient-dashboard-img.png",
      text: "“The platform gave me the independence to provide care the way I wanted to. No targets. No hidden costs. I feel at peace too.”",
      name: "Dr Meelie Bordoloi",
    },
    {
      src: "/assets/images/testmon-doc-img02.png",
      text: `" Dr. Buckmiller is amazing. I recommend him very much. I was depressed and would struggle to even get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is vereven get out of bed. I didnat can be treated. Dr. Buckmiller is very knowledgeable and kind. I will recommend him to everyone." `,
      name: "robert Sobule, PA-C",
    },
  ];

  return (
    <>
      <Carousel
        showArrows={true}
        swipeable={true}
        interval={20000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        autoPlay={true}
        stopOnHover={true}
        showIndicators={true}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <img
            src="/assets/images/active-carousel-index-provider.png"
            height={7}
            className={classNames(
              {
                "active-carousel-index": isSelected,
                "in-active-carousel-index": !isSelected,
              },
              "modified"
            )}
          />
        )}
        className="rounded-lg h-72  "
      >
        {items.map((item, idx) => (
          <div key={idx}>
            <div className="   h-96 flex flex-col items-center gap-6 rounded-lg ">
              {/* */}
              <div className="p-4 h-40      mt-8 font-extralight italic overflow-y-auto">
                {item.text}
              </div>

              <img
                className=" h-16 w-16	testimonials-user-image"
                src={item.src}
              ></img>

              <p className="mb-4	text-2xl">{item.name}</p>
              <div className="mt-20"></div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}

function Testimonials() {
  return (
    <div className="self-stretch mt-36 lg:w-full max-md:mt-10 max-md:max-w-full bg-red">
      <div className="flex  relative gap-5 max-md:flex-col max-md:gap-0">
        <div className="flex    flex-col lg:w-[43%]  max-md:ml-0 max-md:hidden bg-red">
          <img
            alt=""
            loading="lazy"
            src="/assets/images/testimonials-bg-img.png"
            className="grow w-full  max-md:w-72"
          />
        </div>
        <div className="flex lg:left-72 flex-col lg:absolute justify-start items-center   lg:w-[57%] max-md:ml-0 max-md:w-full gap-8 bg-red ">
          <div className="mt-16  text-4xl font-bold tracking-wider text-center capitalize text-slate-900 max-md:mt-10 max-md:max-w-full">
            Get to Know Our Provider’s Stories
          </div>

          <div className="lg:absolute top-36  left-12 bg-white rounded-lg max-md:w-full max-md:mx-10">
            <CarouselComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
