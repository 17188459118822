import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import classNames from "classnames";
import { AuthContext } from "../../App";

export function CarouselComponent({ items, onChange }) {
  return (
    <>
      <Carousel
        showArrows={true}
        swipeable={true}
        interval={30000}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        autoPlay={false}
        stopOnHover={true}
        showIndicators={true}
        onChange={onChange}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <img
            src="/assets/images/active-carousel-index-provider.png"
            height={7}
            className={classNames({
              "active-carousel-index": isSelected,
              "in-active-carousel-index": !isSelected,
            })}
          />
        )}
        className="bg-white rounded-lg "
      >
        {items.map((item, idx) => (
          <div key={idx} className="">
            <img className="" src={item.src}></img>
          </div>
        ))}
      </Carousel>
    </>
  );
}

function HomePageOne({ isDevelopment = false }) {
  const authState = useContext(AuthContext);
  const { isAuth, setAuth, userDetails } = authState;
  const [activeIndex, setActiveIndex] = useState(0);
  const data = [
    {
      heading: {
        content: `Built by Providers, for Providers.
    We are
    Cure Culture.`,
        style: "text-4xl",
      },
      subHeading: {
        content: ``,
        style: "",
      },
      subSubHeading: {
        content: `A custom platform for Providers to take their private practice
    online. Our fully automated platform will provide support at every
    step.`,
        style: "",
      },
      src: "/assets/images/main-page-img-01.png",
    },
    {
      heading: { content: `You First.`, style: " text-5xl " },
      subHeading: {
        content: `Cure Culture is the most

      provider-centric platform around.`,
        style: "text-3xl leading-10 ",
      },
      subSubHeading: {
        content: `It is designed to address the daily challenges encountered by healthcare providers.
       The platform was entirely based on the feedback and input from providers. This makes it a platform that prioritises the welfare and  needs of a provider.`,
        src: "/assets/images/main-page-img-01.png",
        style: "text-button_font",
      },
      src: "/assets/images/main-page-img-01.png",
    },
  ];
  return (
    <div className="mt-16 pl-11 max-md:p-2  w-full   max-md:mt-0 max-md:max-w-full">
      <div
        className="
      flex gap-5 justify-end 
      
      max-md:flex-col max-md:gap-0 "
      >
        <div className="flex flex-col w-[43%] h-[75dvh] max-md:h-full max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow mt-4 text-xl max-md:mt-10 max-md:max-w-full">
            {activeIndex === 0 ? (
              <div className="text-6xl font-semibold tracking-wider capitalize leading-[78px] text-slate-900 max-md:max-w-full max-md:text-4xl max-md:leading-[58px]">
                Built by Providers, for Providers. <br />
                We are <br />
                Cure Culture.
              </div>
            ) : (
              <div>
                <div className="text-6xl font-semibold tracking-wider capitalize leading-[78px] text-slate-900 max-md:max-w-full max-md:text-4xl max-md:leading-[58px]">
                  You First.
                </div>
                <br />
                <div className="font-semibold text-3xl">
                  Cure Culture is the most provider-centric platform around.
                </div>
              </div>
            )}
            <div className="mt-10 w-4/5 font-light leading-7 text-black max-md:mt-10 max-md:max-w-full text-body_font_one">
              It is designed to address the daily challenges encountered by
              healthcare providers. The platform was entirely based on
              the feedback and input from providers. This makes it a platform
              that prioritises the welfare and  needs of a provider.
            </div>
            {!isDevelopment && (
              <Link
                to={
                  isAuth && userDetails && userDetails.usertype === "provider"
                    ? "/provider/dashboard"
                    : "/login/provider"
                }
                className="justify-center self-start px-14 py-7 mt-10 ml-9 font-bold text-white capitalize whitespace-nowrap bg-blue-900 rounded-xl max-md:px-5 max-md:my-10  max-md:ml-0"
              >
                <div>Register Now</div>
              </Link>
            )}
          </div>
        </div>
        <div className="flex  flex-col ml-5 w-[57%] max-md:ml-0 max-md:w-full">
          <CarouselComponent items={data} onChange={setActiveIndex} />
        </div>
      </div>
    </div>
  );
}

export default HomePageOne;
