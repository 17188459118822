export const tabListProviderDashboard = [
  {
    name: "Home",
    id: 0,
    icon: "/assets/icons/provider/home.png",
    link: "/provider/dashboard",
    pageName: "/dashboard",
  },
  {
    name: "Profile Management",
    id: 1,
    icon: "/assets/icons/provider/profile-management.png",
    link: "/provider/profile-management",
    pageName: "profile-management",
  },

  {
    name: "Scheduling & Communication",
    id: 2,
    icon: "/assets/icons/provider/scheduling-communication.png",
    link: "/provider/scheduling-communication",
    pageName: "scheduling-communication",
  },
  {
    name: "Care & Collaboration",
    id: 3,
    icon: "/assets/icons/provider/care-collaboration.png",
    link: "/provider/care-collaboration",
    pageName: "care-collaboration",
  },
  {
    name: "Performance and Insights",
    id: 4,
    icon: "/assets/icons/provider/performance-insights.png",
    link: "/provider/performance-and-insights",
    pageName: "performance-and-insights",
  },
];
