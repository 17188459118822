import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { AuthContext } from "../../../../App";
// import "./SchedulingAndCommunication.css";
import DatePicker from "react-datepicker";
import DashboardHoc from "../../DashboardHoc";
import CalendarAppointments from "../widgets/CalendarAppointments";
import useWindowDimensions from "../../../utils/useWindowDimensions";
import MessageWidget from "../widgets/MessageWidget";

function SchedulingAndCommunication() {
  const { userDetails } = useContext(AuthContext);
  const today = new Date();
  const [width] = useWindowDimensions();

  return (
    <DashboardHoc>
      <div className="w-4/5 flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2">
        {width > 768 ? (
          <div className="text-3xl  capitalize">Scheduling & Communication</div>
        ) : (
          <div className="text-2xl  capitalize">Scheduling & Communication</div>
        )}
        <div className="text-body_font_three w-11/12">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
          <div
            id="schedulePicker"
            className="flex w-11/12 mt-10 gap-10 h-[480px] max-md:flex-col max-md:w-full max-md:h-full"
          >
            <CalendarAppointments />
            <MessageWidget />
          </div>
        </div>
      </div>
    </DashboardHoc>
  );
}

export default SchedulingAndCommunication;
