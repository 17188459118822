import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../App";
import useProviderActions from "../../../../../../actions/useProviderActions";

function PersonalStatement() {
  const [
    {
      introduction_for_patient,
      treatment,
      volunteer_work,
      research_interests,
      hobbies,
    },
    setDefaultData,
  ] = useState({
    introduction_for_patient: "",
    treatment: "",
    volunteer_work: "",
    research_interests: "",
    hobbies: "",
  });

  const { userDetails } = useContext(AuthContext);
  const { getProfileData } = useProviderActions();

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProfileData(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setDefaultData(res.data);
          }
        }
      });
  }, []);

  return (
    <div className="p-10 max-md:p-2">
      <span className="text-xxl font-semibold text-color-1 ">
        Personal Statement
      </span>
      <div className="flex flex-col gap-3 my-4 " style={{ color: "#00000099" }}>
        <p>{introduction_for_patient}</p>
        <p>{treatment}</p>
        <p>{volunteer_work}</p>
        <p>{research_interests}</p>
        <p>{hobbies}</p>
      </div>
    </div>
  );
}

export default PersonalStatement;
