import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Select } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

function WhoIm({
  iAmGuardianOf,
  setIAmGuardianOf,
  iAmPatient,
  guardianOf,
  setIAmPatient,
  handleChangeGuardianOf,
}) {
  return (
    <section
      aria-label="who i am?"
      id="who i am?"
      className="bg-white shadow-lg p-10 rounded-lg px-20 capitalize max-md:px-2"
    >
      <FormGroup>
        <div className="flex items-center gap-3 max-md:flex-col max-md:items-start">
          <FormControlLabel
            control={
              <Checkbox
                checked={iAmGuardianOf}
                onChange={(e) => {
                  setIAmGuardianOf(e);
                  setIAmPatient(!e);
                }}
              />
            }
            label="I am a guardian of a"
            aria-label="I am a guardian of a"
            id="I am a guardian of a"
          />
          <div className="w-96 max-md:w-52">
            <Select
              labelId="guardian of"
              id="guardian of"
              disabled={iAmPatient}
              value={guardianOf}
              // label="Guardian of"
              placeholder="Guardian of"
              fullWidth
              aria-label="guardian of"
              onChange={handleChangeGuardianOf}
            >
              <MenuItem value={"Minor"}>Minor</MenuItem>
              <MenuItem value={"Developmentally Disable Adult"}>
                Developmentally Disable Adult
              </MenuItem>
              <MenuItem value={"Incapacitated elderly"}>
                Incapacitated elderly
              </MenuItem>
              <MenuItem value={"Adult"}>Adult</MenuItem>
            </Select>
          </div>
          <div className="px-10 max-md:px-0">
            <FormControlLabel
              control={
                <Checkbox
                  checked={iAmPatient}
                  onChange={(e) => {
                    setIAmPatient(e);
                    setIAmGuardianOf(!e);
                  }}
                />
              }
              label="I am the patient"
              aria-label="I am the patient"
              id="I am the patient"
            />
          </div>
        </div>
      </FormGroup>
    </section>
  );
}

export default WhoIm;
