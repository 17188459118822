function maskInput(input) {
  if (typeof input === "string") {
    if (input.includes("@")) {
      // Mask email
      const [name, domain] = input.split("@");
      const maskedName =
        name[0] + "*".repeat(name.length - 2) + name[name.length - 1];
      return maskedName + "@" + domain;
    } else if (input.length > 2 && /^\d+$/.test(input)) {
      // Mask phone number
      return input[0] + "*".repeat(input.length - 3) + input.slice(-2);
    }
  }
  return input; // Return the original input if it doesn't match email or phone number patterns
}

export { maskInput };
