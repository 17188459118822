import React from "react";
import { useContext } from "react";
import { AuthContext } from "../../App";
import { AUTH_HEADER, baseURL } from "../utils";
import axios from "axios";
import { toast } from "react-toastify";

function useCollaborationsActions() {
  const getCollaborations = async () => {
    try {
      return await axios.get(`${baseURL}/collaborations/users`, AUTH_HEADER);
    } catch (err) {
      toast.error("Something went wrong");
    }
    return [];
  };
  return { getCollaborations };
}

export default useCollaborationsActions;
