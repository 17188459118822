import React, { useState } from "react";
import Select from "react-select";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  Add,
  AddRoadOutlined,
  AddRounded,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { widgets } from "./utils";

const positions = [1, 2, 3, 4];

const UpdateWidgetsForm = ({ close, onUpdate }) => {
  const [selectedWidgets, setSelectedWidgets] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [tableData, setTableData] = useState([]);
  const handleAdd = () => {
    if (selectedWidgets && selectedPosition) {
      const newEntry = {
        id: selectedWidgets.value,
        name: selectedWidgets.label,
        position: selectedPosition.label,
      };
      setTableData([...tableData, newEntry]);
      setSelectedWidgets(null);
      setSelectedPosition(null);
    }
  };

  const handleRemove = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };

  const usedPositions = tableData.map((row) => row.position);
  const usedWidgets = tableData.map((row) => row.id);
  const availableWidgetOptions = widgets
    .filter((widget) => !usedWidgets.includes(widget.id))
    .map((widget) => ({ value: widget.id, label: widget.name }));

  const availablePositionOptions = positions
    .filter((position) => !usedPositions.includes(`Position ${position}`))
    .map((position) => ({ value: position, label: `Position ${position}` }));

  return (
    <div className="w-full">
      <div className=" text-2xl mb-3">Update Home Page</div>
      <div className=" flex gap-4 my-2">
        <Select
          value={selectedWidgets}
          onChange={setSelectedWidgets}
          options={availableWidgetOptions}
          placeholder="Select Widget"
          className="w-60  max-md:w-80 h-10 rounded-xl select-box-upload-doc  "
        />
        <Select
          value={selectedPosition}
          onChange={setSelectedPosition}
          options={availablePositionOptions}
          placeholder="Select Position"
          className="w-60  max-md:w-80 h-10 rounded-xl select-box-upload-doc  "
        />

        <button
          onClick={handleAdd}
          className="text-button_font px-4 bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
          disabled={!selectedWidgets || !selectedPosition}
        >
          <AddRounded color="success" />
          Add
        </button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className="font-semibold">Widget</span>
              </TableCell>
              <TableCell>
                {" "}
                <span className="font-semibold">Position</span>
              </TableCell>
              <TableCell>
                {" "}
                <span className="font-semibold">Remove</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.position}</TableCell>
                <TableCell>
                  <div className="cursor-pointer">
                    <RemoveCircleOutline
                      color="error"
                      onClick={() => handleRemove(index)}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <span className="text-gray_out_text">
        *Only four options can be selected
      </span>
      <div className="flex justify-end gap-3">
        <button
          className="submit-btn-bg-color btn
        text-light_tone_one py-3 
        rounded-xl w-36 max-md:w-full
        my-2
        "
          onClick={() => onUpdate(tableData)}
        >
          {tableData && tableData.length ? "Update" : "Reset"}
        </button>
        <button
          className="border btn
         py-3 
        rounded-xl w-36 max-md:w-full
        my-2
        "
          onClick={close}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UpdateWidgetsForm;
