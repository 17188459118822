import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../actions/useProviderActions";

function SelfdiagnosisSpecialties({
  setSelectedSpecialties,
  selectedSpecialties,
}) {
  const { getMasterSpecialtiesList } = useProviderActions();
  const [allSpecialties, setAllSpecialties] = useState([]);

  const [specialtiesList, setSpecialtiesList] = useState([]);

  const handleUpdate = (e) => {
    const tempData = [...selectedSpecialties];
    if (e.target.checked) {
      tempData.push(e.target.name);
    } else {
      _.remove(tempData, (x) => x === e.target.name);
    }
    setSelectedSpecialties([...tempData]);
  };

  useEffect(() => {
    getMasterSpecialtiesList().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setAllSpecialties(res.data);
        }
      }
    });
  }, []);

  return (
    <div className="my-4  max-md:px-0">
      <div className="bg-gray_out shadow-md p-2 rounded-md my-2 flex flex-col gap-3 pl-10">
        <div className="w-full flex flex-wrap max-md:flex-col">
          {allSpecialties.map((item, index) => (
            <div
              key={item.id}
              className="w-1/3 pr-10  py-1 max-md:flex  max-md:gap-2 max-md:pr-2 max-md:w-full"
            >
              <input
                type="checkbox"
                className="cursor-pointer px-1"
                onChange={handleUpdate}
                name={item.id}
                id={item.id}
                defaultChecked={selectedSpecialties.includes(item.id)}
              />{" "}
              <label className="cursor-pointer" htmlFor={item.id}>
                {item.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default SelfdiagnosisSpecialties;
