import React from "react";
import { Link } from "react-router-dom";

function GetStartedButton() {
  return (
    <div className="w-full flex justify-center ">
      <Link
        to={"/login/provider"}
        className="px-14 py-7 mt-10 ml-9 font-bold text-white capitalize whitespace-nowrap bg-blue-900 rounded-xl max-md:px-5 max-md:mt-10 max-md:ml-2.5"
      >
        <div>Get Started</div>
      </Link>
    </div>

    // <div className="flex justify-center items-center">
    //   <div
    //     className=" max-w-full
    //       text-xl
    //       py-7
    //       px-16
    //       button_font
    //       text-center
    //       mt-16
    //       ml-5
    //       font-bold
    //       text-white
    //       whitespace-nowrap
    //       bg-blue-900
    //       rounded-xl
    //       w-[282px]
    //       max-md:px-5
    //       max-md:mt-10"
    //   >
    //     {/* <Link
    //       to={"/login/provider"}
    //       className="justify-center self-start px-14 py-7 mt-10 ml-9 font-bold text-white capitalize whitespace-nowrap bg-blue-900 rounded-xl max-md:px-5 max-md:mt-10 max-md:ml-2.5"
    //     > */}
    //       Get Started
    //     {/*   </Link> */}
    //   </div>
    // </div>
  );
}

export default GetStartedButton;
