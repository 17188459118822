import React from "react";
import Header from "../../containers/common/Header";
import Footer from "../../containers/main/components/Footer";
import { Alert } from "@mui/material";
import DetailsForm from "./DetailsForm";

function Registration() {
  return (
    <div>
      <Header />
      <div className="w-full bg-complementary_accent_two flex justify-center ">
        <span className="max-md:p-2">
          All minors (below 18) must have a supervised account of a parent or
          guardian
        </span>
      </div>
      <DetailsForm />
      <Footer />
    </div>
  );
}

export default Registration;
