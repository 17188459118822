import React from "react";

function useGetLocalUserDetails() {
  const initialData = {
    isProvider: false,
    isMd: false,
  };
  const localUser = JSON.parse(localStorage.getItem("user-details"));
  if (localUser) {
    if (localUser.usertype === "provider")
      return {
        isProvider: true,
        isMd: localUser.provider_details.is_md_app === "MD",
      };
  } else {
    return { ...initialData };
  }
  return initialData;
}

export default useGetLocalUserDetails;
