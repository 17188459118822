import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

function Footer({ isProvider = false, isPatent = false }) {
  return (
    <div
      className="
      flex p-5 pt-8 md:pl-20
      max-md:flex 
      max-md:flex-wrap
      max-md:gap-4
      max-md:mt-2
      max-md:px-4
      max-md:justify-between
      static"
    >
      <div className="flex flex-col max-md:mt-10 footer-first-box">
        <Link to="/">
          <img
            alt=""
            loading="lazy"
            width={134}
            height={55}
            src="/assets/images/logo-1.png"
            className=""
          />
        </Link>
        <div className="mt-3 text-lg leading-8 text-slate-500">
          Lorem ipsum dolor sit amet consectetur adipiscing elit aliquam
        </div>
        <div className="flex gap-25 justify-start footer-social-icons">
          <Link to="/">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/facebook.png"
              className="aspect-[0.58] w-[11px]"
            />
          </Link>
          <Link to="/">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/twitter.png"
              className="my-auto aspect-[1.27] w-[19px]"
            />
          </Link>
          <Link to="/">
            <img
              alt=""
              loading="lazy"
              src="/assets/images/instagram.png"
              className="aspect-square w-[19px]"
            />
          </Link>
        </div>
      </div>

      <div className=" flex gap-3 flex-col items-start footer-boxes ">
        <div className="text-xl font-bold  text-indigo-950">Services</div>
        <Link
          to={cn({
            "/": isProvider,
            "/provider/home": isPatent,
          })}
          className="mt-5 max-md:mr-11 max-md:text-nowrap lg:text-center"
        >
            {cn({
              "Register As Patient": isProvider,
              "Register As Provider": isPatent,
            })}
        </Link>
        <Link className="text-center" to="/login">
          Features
        </Link>
        <Link className="text-center" to="/login">
          Case Studies
        </Link>
        <Link className="text-center" to="/login">
          Review
        </Link>
        <Link className="text-center" to="/login">
          Updates
        </Link>
      </div>
      <div className=" flex gap-3 flex-col items-start footer-boxes ">
        <div className="text-xl font-bold  text-indigo-950">Company</div>
        <Link className="mt-5 text-center" to="/login">
          About
        </Link>
        <Link className="text-center" to="/login">
          Contact us
        </Link>
        <Link className="text-center" to="/login">
          Careers
        </Link>
        <Link className="text-center" to="/login">
          Culture
        </Link>
        <Link className="text-center" to="/login">
          Blog
        </Link>
      </div>
      <div className=" flex gap-3 flex-col items-start footer-boxes ">
        <div className="text-xl font-bold  text-indigo-950">Support</div>
        <Link className="mt-5 text-center" to="/login">
          Getting Started
        </Link>
        <Link className="text-center" to="/login">
          Help center
        </Link>
        <Link className="text-center" to="/login">
          Server status
        </Link>
        <Link className="text-center" to="/login">
          Report a bug
        </Link>
        <Link className="text-center" to="/login">
          Chat support
        </Link>
      </div>
      <div className=" flex gap-3 flex-col items-start footer-boxes ">
        <div className="text-xl font-bold  text-indigo-950">Contacts us</div>
        <div className="flex gap-1">
          <img
            alt=""
            loading="lazy"
            width={20}
            height={20}
            src="/assets/images/footer-email-icon.png"
            className=""
          />
          admin@cure-culture.com
        </div>
        <div className="flex gap-1">
          <img
            alt=""
            loading="lazy"
            width={20}
            height={20}
            src="/assets/images/footer-phone-icon.png"
            className=""
          />
          5564654546
        </div>
        <div className="flex gap-1">
          <img
            alt=""
            loading="lazy"
            width={20}
            height={20}
            src="/assets/images/footer-location-icon.png"
            className=""
          />
          Delhi
        </div>
      </div>
    </div>
  );
}

export default Footer;
