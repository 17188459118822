import React, { useContext, useEffect, useRef, useState } from "react";
import FormInputComponent from "./FormInputComponent";
import Select from "react-select";
import classNames from "classnames";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { toast } from "react-toastify";
import useProviderActions from "../../../actions/useProviderActions";
import ModalComponent from "../../../components/modals/ModalComponent";
import { AuthContext } from "../../../App";

export default function DocumentSubmission() {
  const [selectedOption, setSelectedOption] = useState(null);
  const [width] = useWindowDimensions();
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const [data, setData] = useState([]);
  const initCatData = { label: "Selected Category", value: "-1" };
  if (userDetails && userDetails.provider_details) {
    initCatData["value"] = userDetails.provider_details.category_id;
    initCatData["label"] = userDetails.provider_details.category_name;
  }
  const [selectedCategory] = useState(initCatData);
  const [uploadedDocumentList, setUploadedDocumentList] = useState([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState("");
  const { getDocumentList, deleteDocument } = useProviderActions();
  const [showUploadFailedModal, setShowUploadFailedModal] = useState(false);
  const getDocuments = () => {
    return getDocumentList().then(({ data }) => {
      setUploadedDocumentList(data.data);
    });
  };
  useEffect(() => {
    getDocuments();
    fetch(`${process.env.REACT_APP_BASE_URL}/categories`, {})
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        // setSelectedCategory(data[0].category_id);
      });
  }, []);

  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const { uploadDocument } = useProviderActions();
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("document", file);
    formData.append("document_type", selectedDocumentType.value);
    formData.append("category", selectedCategory.value);
    uploadDocument(formData).then((res) => {
      if (res.error) {
        setShowUploadFailedModal(true);
      } else {
        getDocuments();
      }
    });
  };
  // if (width < 768) {
  // const handleCategoryChange = (e) => {
  //   setSelectedCategory(e);
  //   setSelectedDocumentType("");
  // };

  const handleDocumentTypeChange = (e) => {
    setSelectedDocumentType(e);
  };

  const categoryOptions = data.map((category) => ({
    label: category.category_name,
    value: category.category_id,
  }));

  const documentTypeOptions = data
    .find((category) => category.category_id == selectedCategory.value)
    ?.document_types.map((documentType) => ({
      label: documentType.document_type,
      value: documentType.document_type_id,
    }));
  if (!data) {
    return null;
  }

  return (
    <div>
      <ModalComponent
        isOpen={showUploadFailedModal}
        className={
          "w-2/3 absolute bg-complementary_accent_one  rounded-2xl p-10 shadow-xl "
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">Document Upload Failure</div>
            <div className="text-subtitle_one w-2/3 text-center">
              We've observed multiple failures in document uploads. Our admin
              will reach out to you directly regarding these documents and
              provide assistance with the documentation process.
            </div>
            <button
              className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-72
          "
              onClick={() => {
                setShowUploadFailedModal(false);
              }}
            >
              Continue
            </button>
          </div>
        }
      />
      <div className="max-md:px-8">
        <div className="text-2xl  mb-4 ">Document Submission</div>
        <div className=" bg-complementary_accent_one shadow-xl rounded-2xl ">
          <div className="p-10 max-md:px-2">
            <span className="text-body_font_one ">
              Please select your provider profile and upload your required
              documents here:
            </span>

            <div
              className="bg-light_tone_one py-8 px-4 rounded-2xl mt-4
         flex gap-2 items-center justify-between
         max-md:flex-col max-md:item-start max-md:px-0 
          "
            >
              <div className="w-96 max-md:w-80 h-10 border">
                {userDetails?.provider_details?.category_name
                  ? userDetails?.provider_details?.category_name
                  : "Please Select Category"}
              </div>
              {/* <Select
                classNames={{
                  control: (state) =>
                    classNames(
                      {},
                      "w-96 max-md:w-80 h-10 rounded-xl select-box-upload-doc"
                    ),
                }}
                options={[selectedCategory]}
                value={selectedCategory}
                // onChange={handleCategoryChange}
              /> */}

              <Select
                classNames={{
                  control: (state) =>
                    classNames(
                      {},
                      "w-96  max-md:w-80 h-10 rounded-xl select-box-upload-doc"
                    ),
                }}
                defaultValue={selectedOption}
                options={documentTypeOptions}
                value={selectedDocumentType}
                onChange={handleDocumentTypeChange}
              />

              <input
                type="file"
                ref={fileInputRef}
                // accept="application/pdf"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                className="ml-10 w-96 max-md:w-80 max-md:ml-0"
                onClick={handleUploadClick}
              >
                <img src="/assets/images/document-upload-btn.png"></img>
              </button>
            </div>
            <div className="text-error flex justify-between">
              <span>*Please select your provider catagory carefully</span>
              <span>*Document should be uploaded in PDF format</span>
            </div>
            <div className="relative overflow-x-auto max-md:max-w-80">
              <table className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Doc
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Doc Desc
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Upload Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Verification Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedDocumentList && uploadedDocumentList.length === 0 ? (
                    <tr>
                      <td className="text-gray_out_text" colSpan={10}>
                        Please Upload Document*
                      </td>
                    </tr>
                  ) : (
                    uploadedDocumentList.map((doc) => {
                      return (
                        <tr
                          key={doc.id}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                          <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {doc.category}
                          </th>
                          <td className="px-6 py-4">{doc.document_type}</td>
                          <td className="px-6 py-4">
                            <span className="flex items-center gap-16">
                              <span className="flex items-center gap-2">
                                <img
                                  alt=""
                                  loading="lazy"
                                  src="/assets/images/verified-green-tick.png"
                                  className="w-5 h-4"
                                />{" "}
                                Uploaded Successful{" "}
                              </span>
                              <img
                                alt=""
                                loading="lazy"
                                src="/assets/images/document-trash-icon.png"
                                className="w-4 h-5 cursor-pointer"
                                onClick={() => {
                                  deleteDocument(doc.id).then(() => {
                                    getDocuments();
                                  });
                                }}
                              />
                            </span>
                          </td>
                          <td className="px-6 py-4 capitalize">
                            {doc.verification_status}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
