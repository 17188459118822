import React from "react";
import Header from "../../common/Header";
import Footer from "../../main/components/Footer";
import useProviderActions from "../../../actions/useProviderActions";

function CSAForm() {
  const { csaFormApplyOnline, csaFormApplyViaForm, csaFormDeny } =
    useProviderActions();

  return (
    <div>
      <div className="">
        <div className="flex flex-col items-center  bg-zinc-100">
          <div className="flex flex-col self-stretch w-full max-md:p-4 max-md:max-w-full flex-center">
            <Header />
            <div className="px-24 my-4 max-md:px-4">
              <div className="text-3xl  ">Verify NPI & Upload Documents </div>
              <div className="text-body_font_one pt-4">
                We would like you to complete your registration and verification
                here, before we proceed. We understand that you may have
                questions or concerns. We would like to address them to the best
                of our ability. Write to us at{" "}
                <span className="text-mid_tone_two cursor-pointer">
                  help@cure-culture.com
                </span>
              </div>
              <div className="my-4  ">
                <div className="text-2xl font-semibold ">
                  Application for Registrations - Under the Controlled
                  Substances Act
                </div>
                <div className="">
                  <div
                    className="bg-complementary_accent_one p-10 max-md:py-6 rounded-2xl shadow-lg my-3
                text-subtitle_one max-md:px-4
                "
                  >
                    <div className="">
                      We offer two DEA license registration methods. To expedite
                      your DEA registration process apply online at{" "}
                      <a
                        href="www.deadiversion.usdoj.gov"
                        target="_blank"
                        className="text-mid_tone_two"
                      >
                        www.deadiversion.usdoj.gov
                      </a>
                      . If applying by mail, complete the form, retain a copy,
                      and send it to the address in Section 7 or use the
                      provided envelope. Confirm that the "MAIL-TO ADDRESS" may
                      differ. For questions, call 800-882-9539 before
                      submission. Do not mail the application if applying
                      online.
                    </div>
                    <div className="flex gap-4 mt-5 max-md:flex-col ">
                      <button
                        onClick={() =>
                          csaFormApplyOnline({
                            status: "apply-online",
                          })
                        }
                        className="btn border px-4 py-2 rounded-xl bg-select_option  btn"
                      >
                        Apply Online
                      </button>
                      <button
                        // onClick={()=>csaFormApplyViaForm({
                        //   status: "form-downloaded",
                        // })}
                        onClick={() =>
                          csaFormApplyOnline({
                            status: "apply-via-post",
                          })
                        }
                        className="btn border px-4 py-2 rounded-xl submit-btn-bg-color text-gray_out btn"
                      >
                        Apply Via Post Download Form Here
                      </button>
                      <button
                        // onClick={csaFormDeny}
                        onClick={() =>
                          csaFormApplyOnline({
                            status: "not-prescribe",
                          })
                        }
                        className="btn border px-4 py-2 rounded-xl bg-complementary_accent_three btn"
                      >
                        I will Not Prescribe Controlled Substances
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default CSAForm;
