import React from "react";
import ModalComponent from "./ModalComponent";
import classNames from "classnames";
import { Error } from "@mui/icons-material";
import Modal from "react-modal";

function ModalError({ message, close }) {
  return (
    <ModalComponent
      isOpen={true}
      className={
        "w-2/3 absolute bg-complementary_accent_one border-none  rounded-lg p-10 shadow-xl"
      }
      contentLabel="Verification Unsuccessful"
      body={
        <div className="flex flex-col gap-3  items-center justify-center">
          <Error width={76} height={71} />
          <div className="text-2xl max-md:text-subtitle_two text-complementary_accent_three">
            ERROR
          </div>

          <div className="text-button_font w-2/3 text-center max-md:w-full max-md:text-subtitle_three">
            {message}
          </div>
          <button
            className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-72 max-md:w-full
  "
            onClick={close}
          >
            OK
          </button>
        </div>
      }
    />
  );
}

export default ModalError;
