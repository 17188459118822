import React from "react";
import { Link } from "react-router-dom";

function MessageFromCEO() {
  return (
    <React.Fragment>
      <div
        className="flex  ml-5
        lg:-mt-44
        max-md:-mt-24
        lg:ml-5
        max-md:flex-col
        max-md:mx-2
      "
      >
        <div
          className="flex lg:absolute left-[11px] flex-col lg:gap-24 max-md:gap-14 lg:w-[560px] items-center justify-center
        "
        >
          <div
            className="text-3xl
            lg:absolute
          font-bold
          tracking-wider
          text-center
          capitalize
          text-slate-900
          max-md:mt-10
          max-md:max-w-full
          
          top-[60px]
          left-[60px]
          "
          >
            MESSAGE FROM CEO
          </div>
          <div className="text-xl lg:absolute top-[196px] left-[60px]">
            Thank you for visiting Cure-Culture, the online clinic that strives
            to take care of you and your loved ones at your most vulnerable
            moments. We understand how significant this responsibility is. So
            instead of telling you how awesome this platform is, let me tell you
            how vulnerable we are and give you a glimpse of our journey. I'm an
            engineer who built a career in finance tech. This journey began when
            my wife, a psychiatrist, became frustrated with the limitations of
            traditional mental healthcare.{"  "}
            <Link className="text-blue-600 underline" to="about-us">
              Read more
            </Link>
          </div>
        </div>
        <div className=" flex  max-h-[558px] max-w-[800px]">
          <img
            loading="lazy"
            src="/assets/images/msg-form-ceo-img-01.png"
            height="558"
            width="800"
            className="grow lg:relative   w-full  max-md:max-w-full left-[60%] "
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default MessageFromCEO;
