import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { uniqueId } from "lodash";
import useWindowDimensions from "../../../utils/useWindowDimensions";

const Sidebar = ({ setComposing }) => {
  return (
    <div className="w-52 bg-gray-800 text-white h-screen p-4">
      <div className="mb-8">
        <button onClick={() => setComposing(true)}>
          <img
            src="/assets/icons/provider/compose-email-btn.png"
            width={138}
            height={48}
          ></img>
        </button>
      </div>
      <ListComponent />
    </div>
  );
};

export default Sidebar;

function ListComponent() {
  const [width] = useWindowDimensions();

  if (width < 768) {
    return null;
  }

  return (
    <Box
      key={uniqueId()}
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      <nav aria-label="main mailbox folders" className="text-dark_tone">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <span className="text-dark_tone">Inbox</span>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <DraftsIcon />
              </ListItemIcon>
              <ListItemText primary="Drafts" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
      <nav aria-label="secondary mailbox folders" className="text-dark_tone">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Trash" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="#simple-list">
              <ListItemText primary="Spam" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
  );
}
