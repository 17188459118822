import React from "react";
import Login from "../../common/login/Login";
function LoginComponent() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginComponent;
