import React, { useMemo, useRef, useState } from "react";
import ModalComponent from "../../../../components/modals/ModalComponent";
import JoditEditor from "jodit-react";

function NotesModal({ isOpen, onClose }) {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  //   const config = useMemo(
  //     () => (),
  //     []
  //   );
  return (
    <div>
      {isOpen ? (
        <div
          className={
            " absolute bg-complementary_accent_one  rounded-2xl p-10 shadow-2xl z-10"
          }
        >
          <div className="flex flex-col gap-3  ">
            <div className="flex flex-col gap-2">
              <label>Subject</label>
              <input type="text" className="w-full border py-2 rounded" />
            </div>

            <div className="flex flex-col gap-2" id="myjoditEditor">
              <label>Description</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={{
                  disablePlugins: "add-new-line",

                  buttons:
                    "undo,redo,bold,italic,strikethrough,ul,ol,indent,outdent,left,table,fullsize",
                  toolbarAdaptive: false,
                }}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => {}}
              />
            </div>
            <div className="flex gap-2">
              <button
                className="submit-btn-bg-color text-gray_out btn py-3 px-5 rounded-xl w-32
                "
                onClick={() => {
                  onClose(false);
                }}
              >
                Send
              </button>
              <button
                className="bg-mid_tone_three btn py-3 px-5 rounded-xl w-32
            "
                onClick={() => {
                  onClose(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default NotesModal;
