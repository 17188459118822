import React from "react";
import useProviderActions from "../../../../../../actions/useProviderActions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function CSA({ downloadUrl }) {
  const { csaFormApplyFromProfile } = useProviderActions();

  return (
    <div className="flex flex-col items-center  bg-complementary_accent_one">
      <div className="flex flex-col self-stretch w-full max-md:p-4 max-md:max-w-full flex-center">
        <div className="px-4 my-4 max-md:px-4">
          <div className="my-4  ">
            <div className="text-2xl font-semibold ">
              Application for Registrations - Under the Controlled Substances
              Act
            </div>
            <div className="">
              <div
                className="py-6 rounded-2xl  my-3
              text-subtitle_one max-md:px-4
              "
              >
                <div className="">
                  We offer two DEA license registration methods. To expedite
                  your DEA registration process apply online at{" "}
                  <a
                    href="www.deadiversion.usdoj.gov"
                    target="_blank"
                    className="text-mid_tone_two"
                  >
                    www.deadiversion.usdoj.gov
                  </a>
                  . If applying by mail, complete the form, retain a copy, and
                  send it to the address in Section 7 or use the provided
                  envelope. Confirm that the "MAIL-TO ADDRESS" may differ. For
                  questions, call 800-882-9539 before submission. Do not mail
                  the application if applying online.
                </div>
                <div className="flex gap-4 mt-5 max-md:flex-col ">
                  <button
                    onClick={() =>
                      csaFormApplyFromProfile({
                        status: "apply-online",
                      })
                        .then((res) => {
                          window.open(res.download_url, "_blank");
                        })
                        .catch(() => {
                          toast.error("Something went wrong");
                        })
                    }
                    className="btn border px-4 py-2 rounded-xl bg-select_option  btn"
                  >
                    Apply Online
                  </button>
                  {downloadUrl ? (
                    <Link to={downloadUrl} target="_blank">
                      <button className="btn border px-4 py-2 rounded-xl submit-btn-bg-color text-gray_out btn">
                        Apply Via Post Download Form Here
                      </button>
                    </Link>
                  ) : (
                    <button
                      onClick={() =>
                        csaFormApplyFromProfile({
                          status: "apply-via-post",
                        })
                          .then((res) => {
                            window.open(res.download_url, "_blank");
                          })
                          .catch(() => {
                            toast.error("Something went wrong");
                          })
                      }
                      className="btn border px-4 py-2 rounded-xl submit-btn-bg-color text-gray_out btn"
                    >
                      Apply Via Post Download Form Here
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSA;
