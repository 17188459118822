import JoditEditor from "jodit-react";
import { useRef, useState } from "react";

const Compose = ({ onClose }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  return (
    <div className="flex flex-col gap-3 absolute bottom-5 right-5 bg-white p-10 shadow-2xl  max-md:w-full max-md:top-20 max-md:z-30 max-md:right-0 ">
      <h2 className="text-subtitle_one">Compose Email</h2>
      <div className="flex flex-col gap-2">
        <label>Subject</label>
        <input
          type="text"
          placeholder="Subject"
          className="w-full border py-2 rounded"
        />
      </div>
      <div className="flex flex-col gap-2">
        <label>To</label>
        <input
          placeholder="To"
          type="text"
          className="w-full border py-2 rounded"
        />
      </div>

      <div className="flex flex-col gap-2" id="myjoditEditor">
        <label>Description</label>
        <JoditEditor
          ref={editor}
          value={content}
          config={{
            disablePlugins: "add-new-line",

            buttons:
              "undo,redo,bold,italic,strikethrough,ul,ol,indent,outdent,left,table,fullsize",
            toolbarAdaptive: false,
          }}
          tabIndex={1} // tabIndex of textarea
          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
          onChange={(newContent) => {}}
        />
      </div>
      <div className="flex gap-2">
        <button
          className="submit-btn-bg-color text-gray_out btn py-3 px-5 rounded-xl w-32
        "
          onClick={() => {
            onClose(false);
          }}
        >
          Send
        </button>
        <button
          className="bg-mid_tone_three btn py-3 px-5 rounded-xl w-32
    "
          onClick={() => {
            onClose(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Compose;
