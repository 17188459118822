import React from "react";
import DashboardHoc from "../../DashboardHoc";
import { Link } from "react-router-dom";
import ReviewManagementWidget from "../widgets/ReviewManagementWidget";
import MyStatesWidget from "../widgets/MyStatesWidget";

function PerformanceAndInsights() {
  return (
    <div>
      <DashboardHoc>
        <div className="w-4/5 flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2 ">
          <div className="text-3xl capitalize">Performance And Insights</div>
          <div className="text-body_font_three w-11/12">
            Manage your information, compliance, appointments, requests,
            security, set your availability and more to make your work smoother.{" "}
            <Link to="#" className="text-blue-600">
              Find out more
            </Link>
          </div>
          <div className="flex w-11/12 mt-10 gap-10 h-96 max-md:flex-col max-md:w-full max-md:p-2 max-md:h-full ">
            <MyStatesWidget />
            <ReviewManagementWidget />
          </div>
        </div>
      </DashboardHoc>
    </div>
  );
}

export default PerformanceAndInsights;
