import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../../../../../App";
import useProviderActions from "../../../../../../../actions/useProviderActions";

function ProfileForPatientsApps() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { userDetails } = useContext(AuthContext);
  const [saving, setSaving] = useState(false);

  const { saveProfile, getProfileData } = useProviderActions();
  const [defaultData, setDefaultData] = useState({
    introduction_for_psychiatrist: "",
    message_for_collaboration: "",
  });
  const onSave = (data) => {
    setSaving(true);
    saveProfile({
      user_id: userDetails.user_id,
      ...data,
    }).then((x) => {
      setSaving(false);
    });
  };

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProfileData(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setDefaultData(res.data);
          }
        }
      });
  }, []);

  return (
    <div className="my-4  px-10">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10">
        <div className="text-xxl font-semibold  capitalize my-4">
          Profile for APP
        </div>
        <form onSubmit={handleSubmit(onSave)}>
          <div className=" flex flex-col gap-2">
            <div>
              <label className="text-gray_out_text">Introduction</label>
              <textarea
                {...register("introduction_for_collaboration")}
                defaultValue={defaultData.introduction_for_collaboration}
                name="introduction_for_collaboration"
                className="w-full border rounded-lg pb-8"
              />
            </div>
            <div>
              <label className="text-gray_out_text">Message to APP</label>
              <textarea
                {...register("message_for_collaboration")}
                defaultValue={defaultData.message_for_collaboration}
                name="message_for_collaboration"
                className="w-full border rounded-lg pb-8"
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={saving}
            className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg my-2"
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </form>
      </section>
    </div>
  );
}

export default ProfileForPatientsApps;
