import React, { useContext } from "react";

import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputComponent from "../InputComponent";
import { useGoogleLogin } from "@react-oauth/google";
import { AuthContext } from "../../../App";
import classNames from "classnames";
import useAuth from "../../../actions/useAuth";

function SignupPageTwo({ heading, subHeading = "", setPageType }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { userType } = useParams();
  const { getGoogleUserDetails } = useAuth();
  const { signupByEmail } = useAuth();
  const onSubmit = (data) => signupByEmail(data, userType);

  const responseFacebook = (response) => {
    // console.log(response);
  };
  const authState = useContext(AuthContext);

  const loginByGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getGoogleUserDetails(tokenResponse.access_token, false, userType);
      authState.setAuth(true);
    },
  });

  return (
    <>
      <div className="py-6 px-16 max-md:p-2">
        <div className="text-2xl">{heading}</div>
        <div className="text-body_font_three flex ">
          {subHeading} &nbsp;
          <div
            onClick={() => setPageType("login")}
            className=" text-blue-600	 cursor-pointer"
          >
            Login
          </div>
        </div>
        <div className="flex flex-col gap-6  mt-4">
          <button
            onClick={() => loginByGoogle()}
            className="text-button_font p-4 bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/google-login-img.png"></img>
            Signup with Google
          </button>
        </div>

        {/* <div className="flex gap-10 mt-4">
          <button
            onClick={() => loginByGoogle()}
            className="text-button_font px-14 py-4 max-md:px-5  bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/google-login-img.png"></img>
          </button>
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            autoLoad={false}
            callback={responseFacebook}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                className="z-20 text-button_font px-14 py-4 max-md:px-5 
                hover:border-transparent  border rounded-md text-light_tone_one 
                fb-btn-colors flex justify-center gap-3 items-center"
              >
                <img src="/assets/images/facebook-login-img.png"></img>
              </button>
            )}
          />

          <button
            disabled={true}
            className="text-button_font px-14 py-4 max-md:px-5 hover:border-transparent  rounded-md text-light_tone_one border bg-dark_tone flex justify-center gap-3 items-center"
          >
            <img src="/assets/images/apple-login-img.png"></img>
          </button>
        </div> */}

        <div className="flex items-baseline my-4">
          <img
            className="w-5/12 h-1 "
            src="/assets/images/login-img-3-1.png"
          ></img>
          <span className="mx-4 text-gray-500">or</span>
          <img
            className="w-5/12 h-1 "
            src="/assets/images/login-img-3-1.png"
          ></img>
        </div>
        <div>
          <form className=" flex flex-col" onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 ">
              <InputComponent
                inputFor={"email"}
                inputName="email"
                inputLabel={"Email"}
                inputType="text"
                inputId="email"
                inputAutocomplete="email"
                register={register}
                validations={{
                  required: { value: true, message: "Email Required" },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format",
                  },
                }}
                inputPlaceholder="Email ID"
                errors={errors.email}
              />
              <br />

              <InputComponent
                inputFor={"password"}
                inputName="password"
                inputLabel={"Password"}
                inputType="text"
                inputId="password"
                inputAutocomplete=""
                register={register}
                validations={{
                  required: { value: true, message: "Password Required" },
                  pattern: {
                    value:
                      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message:
                      "Password must contain 8 letters and at least one letter, one number, and one special character",
                  },
                }}
                inputPlaceholder="Set Your Password Here"
                errors={errors.password}
              />
              <br />
              <div className="text-body_font_three">
                Create a strong password with a mix of letters, numbers and
                symbols
              </div>
              <br />

              <input
                type="submit"
                disabled={errors?.name & errors?.password}
                className={classNames(
                  {
                    "submit-btn-bg-color": !errors.email && !errors.password,
                  },
                  "text-button_font p-4  hover:border-transparent  border rounded-md w-full text-light_tone_one bg-mid_tone_two cursor-pointer"
                )}
                value="Continue"
              ></input>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SignupPageTwo;
