import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../App";
import DashboardHoc from "../../../DashboardHoc";
import ProfilePicture from "../../../../../components/ProfilePicture";
import PersonalProfileWidget from "../../widgets/PersonalProfileWidget";
import MedicalProfileWidget from "../../widgets/MedicalProfileWidget";

function ProfileManagement() {
  const { userDetails } = useContext(AuthContext);

  return (
    <DashboardHoc>
      <div className="w-4/5 flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2">
        <div className="text-3xl capitalize">Profile Management</div>
        <div className="text-body_font_three w-11/12">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
          <div className="flex w-11/12 mt-10 gap-10 h-96 max-md:flex-col max-md:w-full max-md:p-2 max-md:h-full ">
            <PersonalProfileWidget />
            <MedicalProfileWidget />
          </div>
           
        </div>
      </div>
    </DashboardHoc>
  );
}

export default ProfileManagement;
