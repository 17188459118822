import React, { useContext } from "react";
import { AuthContext } from "../../../../App";
import ProfilePicture from "../../../../components/ProfilePicture";
import { Link } from "react-router-dom";

function PersonalProfileWidget() {
  const { userDetails } = useContext(AuthContext);

  return (
    <div className="bg-white  shadow-xl rounded-lg p-4 flex w-1/2 max-md:w-full   flex-col max-md:h-full ">
      <div className="text-subtitle_one">Personal Profile</div>
      <div className="my-4">
        <div className="flex gap-2  items-center">
          <ProfilePicture picture={userDetails.picture} />
          <div>
            <p className="text-body_font_two">
              {`${userDetails.prefix}  ${userDetails.first_name} ${userDetails.middle_name} ${userDetails.last_name} ${userDetails.suffix} `}
            </p>
            <p className="font-normal">
              <span className="text-gray_out_text">Gender:</span>
              {userDetails.gender || "Male"}
            </p>
            <p className="font-normal">
              <span className="text-gray_out_text">Pronoun:</span>
              He/Him
            </p>
          </div>
        </div>

        <img
          className="w-full h-0.5 my-5"
          src="/assets/images/login-img-3-1.png"
        ></img>
        <div className="flex flex-col gap-2">
          <span className="flex gap-2">
            <p className="text-gray_out_text flex gap-2 items-center">
              <img
                src={"/assets/images/email-icon.png"}
                className="h-3 w-3 "
                aria-hidden="true"
              />
              Email:
            </p>
            <p>{userDetails.email}</p>
          </span>
          <span className="flex gap-2 items-center">
            <img
              src={"/assets/images/call-chat-svgrepo-com.png"}
              className="h-3 w-3 "
              aria-hidden="true"
            />
            <p className="text-gray_out_text">Phone Number:</p>
            <p>{userDetails.phone_number}</p>
          </span>
          <div className="flex gap-2">
            <p className="text-gray_out_text">Address:</p>
            <span className="flex gap-2">
              <p>{`${userDetails.address_line1} ${userDetails.address_line2} ${userDetails.city} ${userDetails.zip_code}  ${userDetails.state}`}</p>
            </span>
          </div>
        </div>
        <img
          className="w-full h-0.5 my-5"
          src="/assets/images/login-img-3-1.png"
        ></img>
        <Link
          to="/provider/profile-management/personal-profile"
          className="flex justify-end mb-2"
        >
          <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
            View Details
          </button>
        </Link>
      </div>
    </div>
  );
}

export default PersonalProfileWidget;
