import "./index.css";
function Checkboxes({ handleCheckboxChange }) {
  return (
    <div className="my-2">
      <div className="weekDays-selector flex gap-4">
        <input
          type="checkbox"
          id="weekday-mon"
          name="mon"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-mon">M</label>
        <input
          type="checkbox"
          id="weekday-tue"
          name="tue"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-tue">T</label>
        <input
          type="checkbox"
          id="weekday-wed"
          name="wed"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-wed">W</label>
        <input
          type="checkbox"
          id="weekday-thu"
          name="thu"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-thu">T</label>
        <input
          type="checkbox"
          id="weekday-fri"
          name="fri"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-fri">F</label>
        <input
          type="checkbox"
          id="weekday-sat"
          name="sat"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-sat">S</label>
        <input
          type="checkbox"
          id="weekday-sun"
          name="sun"
          onChange={handleCheckboxChange}
          className="weekday"
        />
        <label htmlFor="weekday-sun">S</label>
      </div>
    </div>
  );
}

export default Checkboxes;
