import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import useWindowDimensions from "../utils/useWindowDimensions";
import HeaderDropDownMenu from "./HeaderDropDownMenu";
import HeaderDropDownMenuMobile from "./HeaderDropDownMenuMobile";
import { AuthContext } from "../../App";
import useUserActions from "../../actions/useUserActions";

function Header({
  isProvider = false,
  isPatent = false,
  isLoginComponent = false,
}) {
  const authState = useContext(AuthContext);
  const { isAuth, setAuth, userDetails } = authState;
  const location = useLocation();

  const { logout } = useUserActions();
  useEffect(() => {
    const authToken = localStorage.getItem("auth-token");
    if (authToken) setAuth(true);
  }, []);

  const url = location.pathname.split("/");
  const useType = url[url.length - 1];
  const [width] = useWindowDimensions();
  const [isNavOpen, setIsNavOpen] = useState(false);

  if (width < 768) {
    return (
      <div
        className=" header ease-in duration-300 flex w-full h-full   
      justify-between border-b border-gray-400 py-8"
      >
        <Link to="/">
          <img
            alt=""
            loading="lazy"
            src="/assets/images/logo.png"
            className="w-[114px] top-8 absolute "
          />
        </Link>
        <nav>
          <section className="">
            <div className="-m-5" onClick={() => setIsNavOpen((prev) => !prev)}>
              <svg
                className={cn(
                  { hidden: isNavOpen },
                  "fill-current h-6 w-6 right-4 absolute"
                )}
                viewBox="0 0 20 20"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </div>

            <div
              className={
                isNavOpen
                  ? "showMenuNav ease-in duration-300"
                  : "hideMenuNav ease-in duration-300"
              }
            >
              <div className="flex flex-col ease-in duration-300">
                <div className="flex justify-evenly">
                  <div
                    className="absolute top-0 right-0 px-4 py-8"
                    onClick={() => setIsNavOpen(false)}
                  >
                    <svg
                      className="h-8 w-8 text-gray-600"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <line x1="18" y1="6" x2="6" y2="18" />
                      <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                  </div>
                </div>
                <ul className="flex w-3/4 absolute  flex-col max-md:top-24 max-md:left-6  min-h-[250px]">
                  <Link to="/about-us" className="max-md:py-4">
                    <li className="flex gap-1 items-center">About</li>
                  </Link>
                  <Link to="/" className="max-md:py-4">
                    <li className="flex gap-1 items-center">Areas we serve</li>
                  </Link>
                  <HeaderDropDownMenuMobile
                    title={"Services offered"}
                    menuItems={[
                      [
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                        { label: "Edit", path: "/" },
                      ],
                      [
                        { label: "Delete", path: "/" },
                        { label: "Run", path: "/" },
                      ],
                    ]}
                    isMultiItems={true}
                  />
                  <HeaderDropDownMenuMobile
                    title={"Conditions we treat"}
                    menuItems={[
                      [
                        { label: "Test", path: "/" },
                        { label: "Test", path: "/" },
                      ],
                    ]}
                  />
                  <HeaderDropDownMenuMobile
                    title={"Our Providers"}
                    menuItems={[
                      [
                        { label: "Test", path: "/" },
                        { label: "Test", path: "/" },
                      ],
                    ]}
                  />
                  <HeaderDropDownMenuMobile
                    title={"Our service model"}
                    menuItems={[
                      [
                        { label: "Test", path: "/" },
                        { label: "Test", path: "/" },
                      ],
                    ]}
                  />
                  {isAuth &&
                  userDetails &&
                  userDetails.usertype === "provider" ? null : (
                    <Link
                      to={cn({
                        "/registration/and/verification/": isProvider && isAuth,
                        "/": isProvider,
                        "/home/provider": isPatent,
                      })}
                      className="mt-2"
                    >
                      <li className="grow whitespace-nowrap">
                        {cn({
                          "Register As Patient": isProvider,
                          "Register As Provider": isPatent,
                        })}
                      </li>
                    </Link>
                  )}
                  {!isLoginComponent && !authState.isAuth ? (
                    <Link to={`/login/${useType}`} className="mt-4">
                      <li className="justify-center self-stretch px-10 py-3.5 my-auto font-bold text-white whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5">
                        Login / Sign Up
                      </li>
                    </Link>
                  ) : null}
                  {isAuth ? (
                    <button
                      onClick={logout}
                      className="justify-center cursor-pointer self-stretch px-10 py-3.5 my-auto font-bold text-white whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5"
                    >
                      Logout
                    </button>
                  ) : null}
                </ul>
              </div>
            </div>
          </section>
        </nav>
        <style>{`
    .hideMenuNav {
      display: none;
    }
    .showMenuNav {
      display: block;
      width: 100%;
      height: 200vh;
      top: 0;
      left: 0;
      background: white;
      z-index: 10;
    
    }
  `}</style>
      </div>
    );
  }
  return (
    <div
      className={cn(
        {
          "bg-patient-header": isPatent,
        },
        "flex items-center justify-between px-2 py-2 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full"
      )}
    >
      <Link to="/" className="text-left">
        <img
          alt=""
          loading="lazy"
          src="/assets/images/logo.png"
          className="self-stretch max-w-full mix-blend-multiply ml-2 w-36"
        />
      </Link>
      <div className="flex w-4/6 gap-4 text-sm items-baseline justify-evenly text-indigo-950 max-md:flex-wrap max-md:max-w-full">
        <Link to="/about-us">About</Link>
        <Link to="/">Areas we serve</Link>
        <HeaderDropDownMenu
          title={"Services offered"}
          menuItems={[
            [
              { label: "Edit", path: "/" },
              { label: "Edit", path: "/" },
            ],
            [
              { label: "Delete", path: "/" },
              { label: "Run", path: "/" },
            ],
          ]}
          isMultiItems={true}
        />
        <HeaderDropDownMenu
          title={"Conditions we treat"}
          menuItems={[
            [
              { label: "Test", path: "/" },
              { label: "Test", path: "/" },
            ],
          ]}
        />
        &nbsp;
        <HeaderDropDownMenu
          title={"Our Providers"}
          menuItems={[
            [
              { label: "Test", path: "/" },
              { label: "Test", path: "/" },
            ],
          ]}
        />
        <HeaderDropDownMenu
          title={"Our service model"}
          menuItems={[
            [
              { label: "Test", path: "/" },
              { label: "Test", path: "/" },
            ],
          ]}
        />
        &nbsp;
        {isAuth && userDetails && userDetails.usertype === "provider" ? null : (
          <Link
            to={cn({
              "/registration/and/verification/": isProvider && isAuth,
              "/home/patient": isProvider,
              "/home/provider": isPatent,
            })}
          >
            <div className="grow whitespace-nowrap">
              {cn({
                "Register As Patient": isProvider,
                "Register As Provider": isPatent,
              })}
            </div>
          </Link>
        )}
      </div>
      <div className="">
        {!isLoginComponent && !authState.isAuth ? (
          <Link to={`/login/${useType}`}>
            <div className="justify-center button_font self-stretch px-10 py-3.5 my-auto text-white whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5">
              Login / Sign Up
            </div>
          </Link>
        ) : (
          <div className="justify-center button_font self-stretch px-10 py-3.5 my-auto text-white whitespace-nowrap  rounded-lg max-md:px-5">
            {"               "}
          </div>
        )}
        {isAuth ? (
          <button
            onClick={logout}
            className="justify-center button_font self-stretch px-10 py-3.5 my-auto text-white whitespace-nowrap bg-blue-900 rounded-lg max-md:px-5"
          >
            Logout
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default Header;
