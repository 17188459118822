import React from "react";
import ReactDOM from "react-dom/client";
import "./output.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UnderMaintenance from "./containers/common/UnderMaintenance";
const root = ReactDOM.createRoot(document.getElementById("root"));

if (process.env.REACT_APP_UNDER_MAINTENANCE.toLowerCase() == "true") {
  root.render(<UnderMaintenance />);
} else
  root.render(
    <React.StrictMode>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_AUTH_KEY_CLIENT_ID}
      >
        {/* <UnderDevelopment /> */}
        <App />
      </GoogleOAuthProvider>
    </React.StrictMode>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
