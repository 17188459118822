import React from "react";
import Footer from "../main/components/Footer";
import Header from "../common/Header";
import Home from "./Home";

function PatientHome() {
  return (
    <div>
      <div className="flex flex-col items-center  bg-zinc-100">
        <div className="flex flex-col self-stretch w-full max-md:px-5 max-md:max-w-full flex-center">
          <Header isPatent={true}  />
          <Home />
          <br />
          <div className="lg:mb-64">
            { " "  }
          </div>
        </div>
      </div>
      <Footer isPatent={true} />
    </div>
  );
}

export default PatientHome;
