import React, { useState } from "react";
import Header from "../Header";
import LoginPageOne from "./LoginPageOne";
import Footer from "../../main/components/Footer";
import { useParams } from "react-router-dom";
import SignupOne from "../signup/SignupOne";
import SignupPageTwo from "../signup/SignupPageTwo";

function Login() {
  const { userType } = useParams();
  const [pageType, setPageType] = useState("login");
  const Page = () => {
    switch (pageType) {
      case "register":
        return (
          <SignupOne
            heading="Welcome to Cure Culture !"
            subHeading="Already have an account?"
            setPageType={setPageType}
          />
        );
      case "register-email":
        return (
          <SignupPageTwo
            heading="Welcome to Cure Culture !"
            subHeading="Already have an account?"
            setPageType={setPageType}
          />
        );

      default:
        return (
          <LoginPageOne
            heading="Welcome to Cure Culture !"
            subHeading="Don’t have an account?"
            setPageType={setPageType}
          />
        );
    }
  };
  return (
    <>
      <div className="bg-light_tone_one  max-md:p-2">
        <div className="pt-6 max-md:p-0 max-md:z-20 max-md:relative">
          <Header isLoginComponent={true} />
        </div>
        <div className="w-full ">
          <div
            className="max-md:w-full  mt-20 ml-60 max-md:mx-0 max-md:ml-1  max-md:mt-5 max-md:p-5 
          login-form-container-one lg:absolute w-5/12 z-20	 
          bg-white  rounded-2xl max-md:top-0 max-md:left-0  h-[85%] "
          >
            <Page />
            {pageType === "register" ? <div></div> : null}
          </div>
        </div>
        <div className="flex w-full justify-end">
          <img
            className=" z-1 relative w-1/2 h-screen  max-md:hidden"
            src={`/assets/images/${userType}-login-one-img-001.png`}
          ></img>

          <img
            className=" z-0  w-1/2 h-screen  	 max-md:hidden   absolute"
            src={`/assets/images/provider-login-one-img-002.png`}
          ></img>
          <div className=" text-white z-20 max-md:hidden">
            <div className=" text-button_font  absolute bottom-40 right-64">
              Why Onboard ?
            </div>
            <div className="absolute bottom-20 text-body_font_three w-[500px] right-14">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
