import React from "react";
import HomePageOne from "./HomePageOne";
import HowToRegASProvider from "./HowToRegASProvider";
import GetStartedButton from "./GetStartedButton";
import HomePageSectionFour from "./HomePageSectionFour";
import Testimonials from "./Testimonials";
import Eligibility from "./Eligibility";
import GetInTouch from "./GetInTouch";
import Footer from "../main/components/Footer";
import Header from "../common/Header";
import WhyUs from "../patient/WhyUs";
function HomePage() {
  return (
    <div>
      <div className="flex flex-col items-center pt-6 bg-zinc-100">
        <div className="flex flex-col self-stretch w-full max-md:px-5 max-md:max-w-full flex-center">
          <Header isProvider={true} />
          <HomePageOne />
          <HowToRegASProvider />
          <GetStartedButton />
          <WhyUs />
          <HomePageSectionFour />
          <Testimonials />
          <Eligibility />
          <GetInTouch />

          <br />
        </div>
      </div>
      <Footer isProvider={true} />
    </div>
  );
}

export default HomePage;
