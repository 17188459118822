import React, { useState } from "react";
import ModalComponent from "./ModalComponent";
import classNames from "classnames";
import { Button } from "@mui/material";
import { maskInput } from "./utils";

function PhoneVerificationModal({ isOpen, onClose, number = "" }) {
  const body = (
    <div className="p-10  flex justify-center gap-5 flex-col items-center">
      <img src="/assets/icons/provider/verification-icon.png" />
      <div className="text-2xl font-bold">
        Please verify the code sent to your mobile number {maskInput(number)}{" "}
      </div>

      <input
        className={classNames(
          "block flex-1 border-2  rounded-md my-5  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6  py-5 pl-2 w-full"
        )}
        placeholder={"Enter Code"}
      />
      <div className="flex gap-3">
        <button
          className="submit-btn-bg-color btn text-light_tone_one py-3 px-5 rounded-xl w-48 max-md:w-full
  "
        >
          Submit
        </button>

        <button
          className="bg-complementary_accent_three btn  py-3 px-5 rounded-xl w-48 max-md:w-full
        "
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      <Button variant="text">Resend code</Button>
    </div>
  );
  return <ModalComponent isOpen={isOpen} body={body}></ModalComponent>;
}

export default PhoneVerificationModal;
