import React from "react";
const Card = ({ name, rate, description, helpingRate = " Pay Period*" }) => {
  return (
    <div className="w-72 max-md:w-full h-[508px] rounded-3xl border-color-1 border-2 flex flex-col items-center shadow-lg">
      <div className="my-10 font-extrabold text-2xl">{name}</div>
      <div
        //   className="w-full"
        className="membership-card-btn-bg w-full  flex items-center justify-center "
      >
        <button className="max-w-52 h-14   text-complementary_accent_one bg-dark_tone rounded-md shadow-md flex justify-center items-start align-middle p-3">
          <div className="font-bold " style={{ fontSize: "24px" }}>
            ${rate}/
          </div>
          <div className="max-w-16" style={{ fontSize: "12px" }}>
            {helpingRate}
          </div>
        </button>
      </div>
      <div className="w-72 h-64  max-md:w-full rounded-b-md px-10 text-center bg-[#223f9914] font-light ">
        <div className="py-2">{description}</div>
      </div>
      <button className="bg-mid_tone_three px-10 py-4 rounded-md shadow-lg text-mid_tone_two my-2 font-medium">
        Subscribe
      </button>
    </div>
  );
};
function PlanAndPayments() {
  return (
    <section
      className="my-4 text-dark_tone capitalize"
      aria-label="Plan And Payments"
      id="Plan And Payments"
    >
      <div className="text-2xl my-2 text-color-1 font-semibold ">
        Plan And Payments
      </div>
      <div className="bg-complementary_accent_one p-10 max-md:p-2  rounded-2xl  shadow-lg my-2">
        <div className="text-2xl text-color-1 font-semibold  my-2">
          Membership
        </div>
        <div className="text-subtitle_one pb-2 max-md:px-2 ">
          Welcome to our community! We're excited to support you on your mental
          health journey. To ensure you get the most out of our services, we
          offer three different membership plans. Each plan is designed to cater
          to different needs and budgets.{" "}
          <strong>
            Our payment plans are based on a two-week pay period*.
          </strong>
        </div>
        <div className="flex justify-between my-2 max-md:flex-col max-md:gap-2">
          <Card
            name={"Essential"}
            rate={"16.99"}
            description="This plan is a great starting point for those seeking occasional consultations. However, consultations with this plan have a higher consultation fee."
          />
          <Card
            name={"Balance"}
            rate={"19.99"}
            description="This plan offers a good balance between affordability and frequent consultations with a moderate consultation fee."
          />
          <Card
            name={"Freedom"}
            rate={"24.99"}
            description="This plan is ideal for those seeking regular consultations and comes with the lowest consultation fee per session."
          />
          <Card
            name={"Trial"}
            rate={"0"}
            description={`If you'd prefer to try out our services before committing to a plan. You may select "No subscription" for your assessment.`}
            helpingRate={"for 1st Appointment"}
          />
        </div>
      </div>
    </section>
  );
}

export default PlanAndPayments;
