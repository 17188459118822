import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import { Link, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../../App";
import useAuth from "../../../actions/useAuth";
import classNames from "classnames";

function LoginPageOne({ heading, subHeading = "", setPageType }) {
  const { userType } = useParams();
  const { loginByEmail } = useAuth();
  const { getGoogleUserDetails } = useAuth();
  const responseFacebook = (response) => {
    // console.log(response);
  };
  const authState = useContext(AuthContext);
  const loginByGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      getGoogleUserDetails(tokenResponse.access_token, false, userType);
      authState.setAuth(true);
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useLayoutEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []); // Dependency array to trigger the effect

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordPage, setShowPasswordPage] = useState(false);
  const onLogin = ({ email, password }) =>
    loginByEmail(
      {
        email,
        password,
      },
      userType
    );
  if (showPasswordPage) {
  }
  return (
    <>
      <div className="py-6 px-16 max-md:p-2">
        <div className="text-2xl">
          {heading}
          {!showPasswordPage && (
            <div className="flex text-body_font_three ">
              {subHeading} &nbsp;
              <div
                onClick={() => setPageType("register-email")}
                className=" text-blue-600	 cursor-pointer"
              >
                Sign up
              </div>
            </div>
          )}
        </div>
        {!showPasswordPage && (
          <div className="flex flex-col gap-6  mt-4">
            <button
              autoFocus={true}
              onClick={() => loginByGoogle()}
              className="text-button_font p-4 bg-transparent border border-light_tone_three rounded-md flex justify-center gap-3 items-center"
            >
              <img src="/assets/images/google-login-img.png"></img>
              Login with Google
            </button>
          </div>
        )}{" "}
        {!showPasswordPage && (
          <div className="flex items-baseline">
            <img
              className="w-5/12 h-1 "
              src="/assets/images/login-img-3-1.png"
            ></img>
            <span className="mx-4 text-gray-500 my-4 h-1">or</span>
            <img
              className="w-5/12 h-1 "
              src="/assets/images/login-img-3-1.png"
            ></img>
          </div>
        )}
        <form onSubmit={handleSubmit(onLogin)} className="flex flex-col gap-3">
          <div>
            <label
              htmlFor={email}
              className={classNames(
                "block text-sm font-medium my-3 text-gray-900"
              )}
            >
              Email Id
            </label>
            <input
              type={"email"}
              name={"email"}
              {...register("email", { required: true })}
              id={"email"}
              autoComplete={email}
              className={classNames(
                "block flex-1 border-2  rounded-md  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-[#EFF6FF] py-5 pl-2 w-full"
              )}
              placeholder={"Email Id"}
            />
          </div>

          <div>
            <label
              htmlFor={password}
              className={classNames(
                "block text-sm font-medium leading-6 text-gray-900"
              )}
            >
              Password
            </label>
            <input
              type={"password"}
              name={"password"}
              id={"password"}
              autoComplete={"password"}
              {...register("password", { required: true })}
              className={classNames(
                "block flex-1 border-2  rounded-md  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-[#EFF6FF] py-5 pl-2 w-full"
              )}
              placeholder={"Password"}
            />
          </div>
          <input
            type="submit"
            className={classNames(
              "text-button_font p-4 submit-btn-bg-color  hover:border-transparent  border rounded-md w-full text-light_tone_one bg-mid_tone_two cursor-pointer"
            )}
            value="Log in"
          ></input>
        </form>
      </div>
    </>
  );
}

export default LoginPageOne;
