import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import { Link } from "react-router-dom";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderDropDownMenuMobile({ title, menuItems }) {
  return (
    <Menu as="div" className="text-left w-full max-md:py-4">
      <div>
        <Menu.Button className="flex text-nowrap gap-1   items-center ">
          <div className="">{title}</div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Menu.Items}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="flex flex-col">
          {menuItems.map((items, idx) => (
            <Menu.Items
              key={idx}
              className="z-10 mt-2  origin-top-right divide-y divide-gray-100 rounded-md 
              bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none"
            >
              {items.map((item, idx) => {
                return (
                  <div key={idx} className="py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          <Link
                            to={item.path}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block text-sm p-4"
                            )}
                          >
                            {item.label}
                          </Link>

                          <hr />
                        </>
                      )}
                    </Menu.Item>
                  </div>
                );
              })}
            </Menu.Items>
          ))}
        </div>
      </Transition>
    </Menu>
  );
}
