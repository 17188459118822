import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../App";

const Activate = ({ children }) => {
  const { isAuth, userDetails } = useContext(AuthContext);
  const navigate = useNavigate();
  const lastRoute = sessionStorage.getItem("lastRoute");

  useLayoutEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []); // Dependency array to trigger the effect

  // const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    if (isAuth) {
      if (userDetails && userDetails.usertype === "provider") {
        if (userDetails.is_new == 1) {
          navigate("/registration/and/verification");
          return;
        } else {
          if (lastRoute) {
            navigate(lastRoute);
            return;
          }
          //TODO: will add back once apis are there
          if (
            userDetails.provider_details.is_md_app === "MD" &&
            userDetails.provider_details.controlled_substance_status ===
              "pending"
          ) {
            navigate("/registration/and/verification/csa");
            return;
          }

          navigate("/provider/dashboard");
          return;
        }
      } else {
        if (userDetails && userDetails.is_new == 1) {
          navigate("/patient/registration");
          return;
        }
        navigate("/home/dashboard");
        return;
      }

      // else {
      //   if (lastRoute) {
      //     navigate(lastRoute);
      //   } else if (userDetails && userDetails.usertype === "provider") {
      //     navigate("/home/provider/dashboard");
      //   } else {
      //     navigate("/home/patient");
      //   }
      // }
    }

    if (!isAuth) {
      navigate("/home/patient");
      return;
    }
    // else {
    // }
    navigate("/home/patient");
  }, [isAuth]);
  return <div>{children}</div>;
};

export default Activate;
