import React from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DashboardHoc from "../../DashboardHoc";
import MyPatientList from "../widgets/MyPatientList";
import CollaborationWidget from "../profile/personal-profile/CollaborationWidget";

function CareAndCollaboration() {
  return (
    <DashboardHoc>
      <div className="w-4/5 flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:h-full max-md:p-2">
        <div className="text-3xl capitalize">Care & Collaboration</div>
        <div className="text-body_font_three w-11/12">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother.{" "}
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
          <div className="flex w-11/12 mt-10 gap-10 h-96 max-md:flex-col max-md:w-full max-md:h-full">
            <MyPatientList />
            <CollaborationWidget />
          </div>
           
        </div>
      </div>
    </DashboardHoc>
  );
}

export default CareAndCollaboration;
