import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ClipLoader } from "react-spinners";
import useUserActions from "../../../../../actions/useUserActions";
import { Badge, Button, Typography } from "@mui/material";
import ChangePassword from "./ChangePassword";
import EmailVerificationModal from "../../../../../components/modals/EmailVerificationModal";
import PhoneVerificationModal from "../../../../../components/modals/PhoneVerificationModal";
import useContactActions from "../../../../../actions/useContactActions";
import StatePicker from "../../../../forms/registration-and-verification/StatePicker";
import { update } from "lodash";
import { toast } from "react-toastify";
import AddAdditionalPhoneModal from "../../../../../components/modals/AddAdditionalPhoneModal";
import AddAdditionalEmailModal from "../../../../../components/modals/AddAdditionalEmailModal";
import ModalComponent from "../../../../../components/modals/ModalComponent";

function ProfileSecurity() {
  const { update: updatePersonalDetails, getUser } = useUserActions();
  const [selectedState, setSelectedState] = useState("");
  const [userDetails, setUser] = useState(null);
  const getUserInfo = async () => {
    const user = await getUser();
    setUser(user.data.user);
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const user = await getUser();
      if (userDetails === null) {
        setUser(user.data.user);
        setSelectedState(user.data.user.state);
      }
    };
    getUserInfo();
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
  } = useForm();
  const [saving, setSaving] = useState(false);

  const onSubmit = async (data) => {
    setSaving(true);
    // Delete email from data object
    delete data.email;

    // Update state with selectedState
    data.state = selectedState;

    try {
      // Call updatePersonalDetails to update the user data
      await updatePersonalDetails(data);

      // Fetch the updated user data
      const updatedUser = await getUser();

      // Update state with the new user data
      setUser(updatedUser.data.user);

      setSaving(false);
    } catch (error) {
      // Show error toast if the update fails
      toast.error("Failed to update user details");
    }
  };

  // Fetch additional contacts based on userDetails.user_id
  const { getAdditionalContact } = useContactActions();
  const [additionalContact, setAdditionalContacts] = useState([]);

  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getAdditionalContact(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setAdditionalContacts(res.data);
          }
        }
      });
    }
  }, [userDetails]);

  const [changingPassword, showPasswordModal] = useState(false);
  const [isOpenVerifyEmail, setOpenVerifyEmail] = useState(false);
  const [isOpenAddEmail, setOpenAddEmail] = useState(false);
  const [isOpenAddPhone, setOpenAddPhone] = useState(false);
  const [isOpenVerifyPhone, setOpenVerifyPhone] = useState(false);
  const { sendVerificationCode, saveAdditionalContacts } = useContactActions();
  const saveAdditionalContact = async ({ isPrimary, value, type }) => {
    const payload = [
      // ...additionalContact,
      {
        contact_type: type,
        contact_detail: value,
        is_primary: isPrimary,
        contact_id: "",
      },
    ];

    saveAdditionalContacts({
      additional_contacts: payload,
    }).then((res) => {
      if (type === "email") setOpenAddEmail(false);
      else setOpenAddPhone(false);
      getAdditionalContact(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setAdditionalContacts(res.data);
          }
        }
      });
    });
  };
  const handleEmailVerifyClick = () => {
    const payload = {};

    setOpenVerifyEmail(true);
  };
  const [showSsnUpdateModel, setShowSSNModel] = useState(false);
  const handleSSNChange = (e) => {
    e.preventDefault();
    setShowSSNModel(true);
    return false;
  };
  const SsnUpdateModelBody = () => {
    return (
      <div className="flex flex-col gap-3  items-center justify-center">
        <img
          src="/assets/images/provider-alert.png"
          alt="alert"
          width={76}
          height={71}
        />
        <div className="text-2xl">
          Please confirm deletion/update of your SSN number.
        </div>
        <div className="text-subtitle_one w-2/3 text-center">
          This action will require you to resign the SSN Verification - W9.pdf
          and re-upload it to the document centre.
        </div>
        <div className="flex gap-2">
          <button
            className="submit-btn-bg-color btn text-light_tone_one py-3 px-2 rounded-xl w-36"
            onClick={() => {}}
          >
            Delete
          </button>

          <button
            className="btn  py-3 px-2 rounded-xl w-36 border bg-complementary_accent_three"
            onClick={() => {
              setShowSSNModel(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="h-full bg-white w-11/12 shadow-lg rounded-lg py-10  ">
      <ChangePassword
        isOpen={changingPassword}
        onClose={() => showPasswordModal(false)}
      />
      <ModalComponent
        isOpen={showSsnUpdateModel}
        body={<SsnUpdateModelBody />}
      ></ModalComponent>

      <EmailVerificationModal
        isOpen={isOpenVerifyEmail}
        onClose={() => setOpenVerifyEmail(false)}
        email={userDetails?.email}
      />
      <PhoneVerificationModal
        isOpen={isOpenVerifyPhone}
        onClose={() => setOpenVerifyPhone(false)}
        number={userDetails?.phone_number}
      />

      <AddAdditionalPhoneModal
        isOpen={isOpenAddPhone}
        submit={saveAdditionalContact}
        onClose={() => setOpenAddPhone(false)}
      />

      <AddAdditionalEmailModal
        isOpen={isOpenAddEmail}
        submit={saveAdditionalContact}
        onClose={() => setOpenAddEmail(false)}
      />

      <span className="block font-semibold text-xxl	pl-10 ">Security</span>
      {userDetails === null ? (
        <ClipLoader
          color={"black"}
          loading={true}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <form className="w-full  px-10 mt-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4 text-body_font_three ">
            <div className="flex max-md:w-full max-md:flex-col max-md:gap-4">
              <div className="w-1/2 max-md:w-full">
                {additionalContact
                  .filter((x) => x.contact_type === "email")
                  .map((contact, idx) => {
                    return (
                      <div className="border p-2 shadow-md my-1  ">
                        <div>
                          {idx > 0 ? (
                            <label className="block">Additional Email</label>
                          ) : (
                            <label className="block">Email</label>
                          )}

                          <input
                            className="w-10/12 border p-2  rounded-md "
                            value={contact.contact_detail}
                            readOnly
                            type="text"
                          />
                          {contact.is_primary == "1" ? (
                            <div className="bg-select_option px-2 my-1 rounded-md w-24">
                              Primary
                            </div>
                          ) : contact.verification_status === "pending" ? (
                            <Button
                              variant="text"
                              onClick={() => setOpenVerifyEmail(true)}
                            >
                              Verify Email
                            </Button>
                          ) : null}
                        </div>
                        {}
                        &nbsp;
                      </div>
                    );
                  })}
              </div>
              <div className="w-1/2 max-md:w-full ">
                {additionalContact
                  .filter((x) => x.contact_type === "phone")
                  .map((contact, idx) => {
                    return (
                      <div className="  border p-2 shadow-md my-1">
                        <div className="">
                          {idx > 0 ? (
                            <label className="block">Additional Phone</label>
                          ) : (
                            <label className="block">Phone</label>
                          )}

                          <input
                            className="w-10/12 border p-2  rounded-md "
                            value={contact.contact_detail}
                            readOnly
                            type="text"
                          />
                          {contact.is_primary == "1" ? (
                            <div className="bg-select_option px-2 my-1 rounded-md w-24">
                              Primary
                            </div>
                          ) : contact.verification_status === "pending" ? (
                            <Button
                              onClick={() => setOpenVerifyPhone(true)}
                              variant="text"
                            >
                              Verify Phone
                            </Button>
                          ) : null}
                        </div>
                        &nbsp;
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className=" flex gap-2 max-md:w-full ">
              <Button
                onClick={() => showPasswordModal(true)}
                variant="contained"
                color="warning"
              >
                Change Password
              </Button>
              <Button variant="outlined" onClick={() => setOpenAddEmail(true)}>
                Add Additional Email
              </Button>
              <Button variant="outlined" onClick={() => setOpenAddPhone(true)}>
                Add Additional Phone Number
              </Button>
            </div>
            <div className="w-1/2 block max-md:w-full ">
              <div>
                <label className=" block">SSN No. </label>
                <input
                  className="w-10/12 border p-2  rounded-md "
                  {...register("ssn", {
                    required: "ssn",
                  })}
                  onChange={handleSSNChange}
                  defaultValue={userDetails.ssn}
                  type="text"
                />
              </div>
            </div>
            <div>
              <div className="text-body_font_two font-semibold">Address</div>
              <div className="flex gap-4 ">
                <div className="w-full flex max-md:flex-col max-md:gap-4">
                  <div className="w-1/2 max-md:w-full">
                    <div>
                      <label className="block">Address Line 1</label>
                      <input
                        className="w-10/12 border p-2  rounded-md "
                        {...register("address_line1", {
                          required: "address_line1",
                        })}
                        value={userDetails.address_line1}
                        type="text"
                      />
                    </div>
                  </div>{" "}
                  <div className="w-1/2 max-md:w-full">
                    <div>
                      <label>Address Line 2</label>
                      <input
                        className="w-10/12 border p-2  rounded-md "
                        {...register("address_line2", {
                          required: "address_line2",
                        })}
                        type="text"
                        value={userDetails.address_line2}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className=" flex my-4 max-md:flex-col max-md:gap-4">
                <div className="w-1/2 max-md:w-full">
                  <div>
                    <label className="block">City</label>
                    <input
                      className="w-10/12 border p-2  rounded-md "
                      {...register("city", {
                        required: "city",
                      })}
                      defaultValue={userDetails.city}
                      type="text"
                    />
                  </div>
                </div>{" "}
                <div className="w-1/4 max-md:w-full">
                  <div>
                    <label className="block">State</label>
                    <StatePicker
                      onChange={setSelectedState}
                      isDisabled={false}
                      showLabel={false}
                      value={selectedState}
                      defaultValue={{
                        label: userDetails.state,
                        value: userDetails.state,
                      }}
                      placeholder="select state"
                    />
                  </div>
                </div>{" "}
                <div className="w-1/4 max-md:w-full">
                  <div>
                    <label className="block">Zip</label>
                    <input
                      className="w-10/12 border p-2  rounded-md "
                      {...register("zip_code", {
                        required: "zip_code",
                      })}
                      defaultValue={userDetails.zip_code}
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input
            disabled={saving}
            type="submit"
            className={classNames(
              "text-button_font p-4 submit-btn-bg-color  hover:border-transparent  border rounded-md w-36 my-4 text-light_tone_one bg-mid_tone_two cursor-pointer "
            )}
            value={saving ? "Saving..." : "Save"}
          ></input>
        </form>
      )}
    </div>
  );
}

export default ProfileSecurity;
