import React, { useState } from "react";
import DocumentCenter from "../../../personal-profile/DocumentCenter";

import Specialties from "./Specialties";
import ProfessionalMemberships from "./ProfessionalMemberships";
import ProfessionalExperience from "./ProfessionalExperience";
import LanguageSpoken from "./LanguageSpoken";
import classNames from "classnames";
import ProfileForPatients from "./ProfileForPatients";
import ProfileForPatientsApps from "./ProfileForPatientsApps";
import ConsultationHoursForPatient from "../../app-users/edit-mode/ConsultationHoursForPatient";

function MainMDEdit({ setIsEditing }) {
  return (
    <div className="w-full">
      <DocumentCenter />

      <ProfessionalMemberships />
      <Specialties />
      <ProfessionalExperience />
      <LanguageSpoken />
      <ConsultationHoursForPatient />
      <ProfileForPatients />
      <ProfileForPatientsApps />
      <div className="flex justify-center">
        <button
          onClick={() => setIsEditing(false)}
          className={
            "border  py-3 px-5 rounded-xl w-72 submit-btn-bg-color text-white"
          }
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default MainMDEdit;
