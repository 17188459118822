import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ModalComponent from "../../../../components/modals/ModalComponent";
import NotesModal from "./NotesModal";
import ProfilePicture from "../../../../components/ProfilePicture";

function CollaboratorDetailsView() {
  const [isTerminateModelOpen, setTerminateModelOpen] = useState(false);
  const [isNotesModelOpen, setNotesModelOpen] = useState(false);
  return (
    <>
      <NotesModal
        isOpen={isNotesModelOpen}
        onClose={() => setNotesModelOpen(false)}
      />
      <ModalComponent
        isOpen={isTerminateModelOpen}
        className={
          "w-1/2 absolute bg-complementary_accent_one  rounded-2xl p-10 shadow-xl "
        }
        contentLabel="Verification Unsuccessful"
        body={
          <div className="flex flex-col gap-3  items-center justify-center">
            <img
              src="/assets/images/provider-alert.png"
              alt="alert"
              width={76}
              height={71}
            />
            <div className="text-2xl">
              Are you sure you want to end this collaboration agreement?
            </div>
            <div className="flex gap-2">
              <button
                className="bg-mid_tone_three btn py-3 px-5 rounded-xl w-72
          "
                onClick={() => {
                  setTerminateModelOpen(false);
                }}
              >
                No, Keep Agreement
              </button>
              <button
                className="bg-[#E4E1E1] btn py-3 px-5 rounded-xl w-72
          "
                onClick={() => {
                  setTerminateModelOpen(false);
                }}
              >
                Yes, End Agreement
              </button>
            </div>
          </div>
        }
      />
      <div className=" bg-gray_out h-[525px] rounded shadow overflow-y-auto mx-4 py-4 ">
        <div className="flex items-start">
          <ProfilePicture />
          <div className="py-2 ">
            <span className="text-body_font_two font-medium ">
              Florine Marquardt
            </span>

            <span className="block text-gray_out_text">
              BDS, MDS - Paedodontics And Preventive Dentistry Dentist,
              Implantologist, Pediatric Dentist,Cosmetic/Aesthetic Dentist,
              Preventive Dentistry, Dental Surgeon 25 Years Experience Overall 
              (24 years as specialist)
            </span>
          </div>
          <button
            onClick={() => setTerminateModelOpen(true)}
            className="w-2/4 py-4 shadow  mr-10 ml-6 my-4 text-body_font_two rounded-lg text-gray_out   font-semibold bg-complementary_accent_three border"
          >
            Terminate Agreement
          </button>
        </div>
        <div className="flex-grow border-t border-gray-400 my-4"></div>

        <div className="px-4">
          <div className="py-4">
            <div className="flex items-center gap-2 font-bold">
              <img
                alt=""
                loading="lazy"
                src="/assets/images/verified-green-tick.png"
                className="w-5 h-4"
              />
              Medical Registration Verified
            </div>
            <div className="flex items-center gap-2 font-bold">
              <img
                alt=""
                loading="lazy"
                src="/assets/images/verified-green-tick.png"
                className="w-5 h-4"
              />
              95% (227 patients)
            </div>
          </div>
          <div className="flex flex-col gap-3 text-gray_out_text ">
            <p>
              Dr. Sumanth M. Shetty completed his post graduation in masters of
              dental surgery[ mds] in the field of Pedodontics and preventive
              dentistry, from the esteemed Bapuji Dental College, Davangere, a
              Karnataka, India. He is now serving as professor and HOD in the
              department of pediatric dentistry.
            </p>
            <p>
              He is also a practicing implantology having completed his implant
              course from the prestigious Nobel Biocare, Sweden. his keen
              interest in the field of implant dentistry has led him to accrue
              further certifications in this field. He is also certified in
              complicated sinus lift implant procedures and advanced full mouth
              rehabilitation from Ossteum, Lisbon and Jakarta respectively.
            </p>
            <p>
              At chisel dental clinic we are proud of our unique brand of gentle
              family dentistry. By explaining your treatment choices every step
              of the way we help you make an informed decision. We treat you
              with honesty, compassion, and respect.
            </p>
          </div>
        </div>

        <div className="flex-grow border-t border-gray-400 my-4"></div>
        <div className="flex w-full flex-wrap gap-10 overflow-x-auto px-10 ">
          <div
            className="font-semibold cursor-pointer relative"
            onClick={() => setNotesModelOpen(true)}
          >
            <img
              src={"/assets/icons/provider/notes.png"}
              className="w-10 h-10 mr-5 c"
            />
            <div className="py-1">Note 1</div>
          </div>
          <div
            className="font-semibold cursor-pointer"
            onClick={() => setNotesModelOpen(true)}
          >
            <img
              src={"/assets/icons/provider/notes.png"}
              className="w-10 h-10 mr-5"
            />
            <div className="py-1">Note 1</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollaboratorDetailsView;
