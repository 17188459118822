import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCollaborationsActions from "../../../../actions/provider/useCollaborationsActions";
import ProfilePicture from "../../../../components/ProfilePicture";

function QuickSendList() {
  const today = new Date();
  const [showFilterByDatePopover, setShowFilterByDatePopover] = useState(false);
  const [selectedDateValue, setDateValue] = useState("");
  const { getCollaborations } = useCollaborationsActions();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await getCollaborations();
      if (response?.data?.status === "success") {
        setData(response.data.data);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="bg-white w-full 0 rounded shadow-lg max-md:h-full h-[500px]">
      <div className="text-subtitle_one px-4 py-4">Quick Send</div>
      <div className="flex items-center">
        <div className="relative">
          <button
            className="py-2 w-48 bg-white shadow border mx-4 "
            onClick={() => {
              setShowFilterByDatePopover(!showFilterByDatePopover);
            }}
          >
            {selectedDateValue
              ? moment(selectedDateValue).format("DD-MM-YYYY")
              : "Filter By Date"}
          </button>
          {showFilterByDatePopover ? (
            <div className="w-96 shadow-lg z-50 h-72 absolute">
              <DatePicker
                startDate={Date.now()}
                onChange={(e) => {
                  setDateValue(e);

                  setShowFilterByDatePopover(!showFilterByDatePopover);
                }}
                inline
                showLeadingZeros={true}
                value={today}
                // filterDate={isWeekday}
                minDate={new Date()}
                className=""
                // maxDate={nextFourWeeks}
                dateFormat="MMMM, yyyy"
                // renderCustomHeader={(props) => <CustomHeader {...props} />}
              />
            </div>
          ) : null}
        </div>

        <Select
          className="w-72 p-4"
          options={[
            { label: "Alphabetical Asc. (A - Z)" },
            { label: "Alphabetical Dsc. (Z - A)" },
          ]}
        ></Select>
      </div>
      <div className=" h-96 px-10 max-md:px-2">
        <div className="bg-gray_out rounded-lg h-72 overflow-auto ">
          <table className="w-full text-sm  text-left rtl:text-right text-gray-500 dark:text-gray-400 max-md:w-full">
            <thead className="text-xs  text-gray_out_text uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="flex justify-between px-10 py-3 max-md:px-2 max-md:justify-start max-md:gap-2">
                <th scope="col" className=" ">
                  Date
                </th>

                <th scope="col" className=" ">
                  Patient Name
                </th>
                <th scope="col" className=" ">
                  Collaborators
                </th>
                <th scope="col" className="">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="relative">
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <tr
                    className={classNames(
                      "flex justify-between px-10 py-4 border-t  max-md:px-3 "
                    )}
                  >
                    <td className="">March 19, 2024</td>
                    <td className="flex items-center gap-2 mr-10">
                      <ProfilePicture />
                      Florine Marquardt
                    </td>
                    <td className="">
                      <Select
                        className="w-52 rounded-md shadow max-md:w-24"
                        options={[{ label: "Collaborators" }]}
                      ></Select>
                    </td>

                    <td className="  text-complementary_accent_three flex ">
                      <button className="submit-btn-bg-color text-white p-2 rounded">
                        Send
                      </button>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default QuickSendList;
