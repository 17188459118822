import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../../App";
import useProviderActions from "../../../../../../actions/useProviderActions";

function AppProfileForCollaboration() {
  const [
    { introduction_for_collaboration, message_for_collaboration },
    setDefaultData,
  ] = useState({
    introduction_for_collaboration: "",
    message_for_collaboration: "",
  });

  const { userDetails } = useContext(AuthContext);
  const { getProfileData } = useProviderActions();

  useEffect(() => {
    if (userDetails && userDetails.user_id)
      getProfileData(userDetails.user_id).then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setDefaultData(res.data);
          }
        }
      });
  }, []);

  return (
    <div className="py-4">
      <div className="text-xxl font-semibold text-color-1 px-10">
        Profile For Collaboration
      </div>

      <div
        className="flex flex-col gap-3 my-4 bg-gray_out p-10 rounded-md"
        style={{ color: "#00000099" }}
      >
        <span className="text-xxl font-semibold text-color-1 ">
          Personal Statement
        </span>

        <p>{introduction_for_collaboration}</p>
        <div className="flex-grow border-t border-gray-400"></div>
        <span className="text-xxl font-semibold text-color-1 ">
          Message to Psychiatrist
        </span>
        <p>{message_for_collaboration}</p>
      </div>
    </div>
  );
}

export default AppProfileForCollaboration;
