import classNames from "classnames";
import React from "react";

function InputComponent({
  inputFor,
  inputLabel,
  inputName,
  inputType,
  inputId,
  inputAutocomplete = "",
  inputPlaceholder,
  register,
  validations,
  errors,
  onChange = () => null,
}) {
  return (
    <div className="">
      <label
        htmlFor={inputFor}
        className={classNames(
          {
            "text-error": errors,
          },
          "block text-sm font-medium leading-6 text-gray-900"
        )}
      >
        {inputLabel}
      </label>
      <div className="mt-2">
        <div className="flex flex-col rounded-md shadow-sm w-full">
          <input
            type={inputType}
            name={inputName}
            onChange={(e) => {}}
            id={inputId}
            autoComplete={inputAutocomplete}
            className={classNames(
              {
                "border-error": errors,
              },
              "block flex-1 border-2  rounded-md  bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 bg-[#EFF6FF] py-5 pl-2 w-full"
            )}
            placeholder={inputPlaceholder}
            {...register(inputName, validations)}
          />
          {errors && <span className="text-error">{errors.message}</span>}
        </div>
      </div>
    </div>
  );
}

export default InputComponent;
