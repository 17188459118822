import React, { useState } from "react";
import DashboardHoc from "../../../DashboardHoc";
import { Link } from "react-router-dom";
import MainMd from "./md-users/MainMD";
import MainMDEdit from "./md-users/edit-mode/MainMDEdit";
import MainApp from "./app-users/MainApp";
import MainAPPEdit from "./app-users/edit-mode/MainAPPEdit";
import useGetLocalUserDetails from "../../../../../hooks/useGetLocalUserDetails";

function MedicalProfile() {
  const { isMd } = useGetLocalUserDetails();

  const [isEditing, setIsEditing] = useState(false);

  return (
    <DashboardHoc>
      <div className="w-4/5 flex gap-4 items-center  flex-col p-10 h-4/5 overflow-auto max-md:w-full max-md:p-2">
        <div className="text-3xl capitalize">Medical Profile </div>
        <div className="text-body_font_three w-11/12">
          Manage your information, compliance, appointments, requests, security,
          set your availability and more to make your work smoother. Find out
          more 
          <Link to="#" className="text-blue-600">
            Find out more
          </Link>
        </div>

        {isMd ? (
          isEditing ? (
            <MainMDEdit setIsEditing={setIsEditing} />
          ) : (
            <MainMd setIsEditing={setIsEditing} />
          )
        ) : isEditing ? (
          <MainAPPEdit setIsEditing={setIsEditing} />
        ) : (
          <>
            <MainApp setIsEditing={setIsEditing} />
          </>
        )}
      </div>
    </DashboardHoc>
  );
}

export default MedicalProfile;
