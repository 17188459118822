import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Info, InfoOutlined, InfoTwoTone } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import { Button } from "@mui/material";
export default function OSPSelectBox({
  item,
  isDisabled,
  onSpecialityChange,
  osp = "0",
}) {
  const [selectedValue, setSelectedValue] = React.useState(osp);
  const [isPopoverOpen, setPopoverOpen] = React.useState(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onSpecialityChange({ osp: event.target.value, speciality: item.id });
  };

  return (
    <div className="flex items-center">
      <div className="w-24">
        <FormControl sx={{ m: 1 }}>
          <Select
            value={selectedValue}
            onChange={handleChange}
            disabled={isDisabled}
            displayEmpty
            inputProps={{ "aria-label": "OSP" }}
          >
            <MenuItem value={"0"}>None</MenuItem>
            <MenuItem value={"1"}>Least</MenuItem>
            <MenuItem value={"2"}>Neutral</MenuItem>
            <MenuItem value={"3"}>Most</MenuItem>
          </Select>
        </FormControl>
      </div>
      <OspPopover />
    </div>
  );
}

function OspPopover() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "osp-popover" : undefined;

  return (
    <div className="">
      <Button aria-describedby={id} variant="text" onClick={handleClick}>
        <InfoOutlined />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onMouseLeave={handleClose}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="flex flex-col p-2">
          <span>
            <strong>Most:</strong> At the top of search results for the specific
            condition
          </span>
          <span>
            <strong>Neutral:</strong>Below the "Most" providers for that
            condition.
          </span>
          <span>
            <strong>Least:</strong> Below the Neutral providers for that
            condition
          </span>

          <span>
            <strong>None:</strong> Profile will not show for patients with that
            condition.
          </span>
        </div>
      </Popover>
    </div>
  );
}
