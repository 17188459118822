import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import OSPSelectBox from "../../../../../../common/OSPSelectBox";

function Specialties() {
  const { getMasterSpecialtiesList, getProviderSpecialtiesList } =
    useProviderActions();
  const [allSpecialties, setAllSpecialties] = useState([]);

  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [specialtiesList, setSpecialtiesList] = useState([]);
  const { updateSpecialties } = useProviderActions();
  const { userDetails } = useContext(AuthContext);

  const handleUpdate = (e) => {
    const tempData = [...selectedSpecialties];
    if (e.target.checked) {
      tempData.push({ speciality: e.target.name, osp: "0" });
    } else {
      _.remove(tempData, (x) => x.speciality === e.target.name);
    }
    setSelectedSpecialties([...tempData]);
    setTimeout(() => {
      onSave([...tempData]);
    }, 2000);
  };
  const onSpecialityChange = (data) => {
    const temp = [...selectedSpecialties];
    const index = selectedSpecialties.findIndex(
      (x) => x.speciality === data.speciality
    );

    temp[index] = data;
    setSelectedSpecialties([...temp]);

    setTimeout(() => {
      onSave([...temp]);
    }, 2000);
  };
  const onSave = (data) => {
    updateSpecialties({
      user_id: userDetails.user_id,
      specialities: data,
    });
  };

  useEffect(() => {
    getMasterSpecialtiesList().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setAllSpecialties(res.data);
        }
      }
    });

    getProviderSpecialtiesList().then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setSelectedSpecialties(res.data);
        }
      }
    });
  }, []);

  return (
    <div className="my-4  px-10 max-md:px-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Select Your Preferred Condition’s and Optimize Search Preferences
          (OSP)
        </div>
        <div className="font-light my-2  ">
          Customize your online profile to boost visibility for patients seeking
          your expertise. Highlight the benefits your skills offer to improve
          search rankings. Choose one categories from the drop down.
        </div>
        <div className="bg-gray_out p-2 rounded-md my-2 flex flex-col gap-3 pl-10">
          <div className="w-full flex flex-wrap max-md:flex-col">
            {allSpecialties.map((item, index) => {
              const selectedSpecialtiesIdx = selectedSpecialties.findIndex(
                (x) => x.speciality === item.id
              );
              const isChecked = selectedSpecialtiesIdx !== -1;
              return (
                <div
                  key={item.id}
                  className="w-1/2 items-center gap-2 pr-10 py-1 max-md:flex  flex max-md:gap-2 max-md:pr-2 max-md:w-full"
                >
                  <input
                    type="checkbox"
                    className="cursor-pointer px-1"
                    onChange={handleUpdate}
                    name={item.id}
                    id={item.id}
                    checked={isChecked}
                  />
                  <label htmlFor={item.id} className="w-48">
                    {item.name}
                  </label>
                  <OSPSelectBox
                    isDisabled={!isChecked}
                    item={item}
                    osp={
                      selectedSpecialtiesIdx > -1
                        ? selectedSpecialties[selectedSpecialtiesIdx].osp
                        : "0"
                    }
                    onSpecialityChange={onSpecialityChange}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="text-right max-md:text-start">
          To personalize your Search Optimization Preference,
          <a target="_blank" className="text-mid_tone_two" href="/">
            click here.
          </a>
        </div> */}
      </section>
    </div>
  );
}
export default Specialties;
