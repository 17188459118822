import React, { useContext, useEffect, useState } from "react";
import useProviderActions from "../../../../../../../actions/useProviderActions";
import { AuthContext } from "../../../../../../../App";
import { useForm } from "react-hook-form";
import classNames from "classnames";

function ProfessionalMemberships() {
  const [membershipData, setMembershipData] = useState([]);
  const [newData, setNewData] = useState([]);

  const { saveProfessionalMembership, getProfessionalMembership } =
    useProviderActions();
  const { userDetails } = useContext(AuthContext);
  const getData = () => {
    getProfessionalMembership(userDetails.user_id).then((res) => {
      if (res.status === 200) {
        if (res.data) {
          setMembershipData(res.data);
        }
      }
    });
  };
  useEffect(() => {
    if (userDetails && userDetails.user_id) getData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    setNewData([...newData, data]);
    reset();
  };
  const [saving, setSaving] = useState(false);

  const onSave = () => {
    setSaving(true);
    const payload = {
      memberships: [...membershipData, ...newData].map(
        ({ id, institution_name, start_year, end_year }) => ({
          id,
          institution_name,
          start_year,
          end_year,
        })
      ),
    };
    saveProfessionalMembership(payload).then(() => {
      setSaving(false);
      setNewData([]);
      getData();
    });
  };

  return (
    <div className="my-4  px-10 max-md:p-0">
      <section className="bg-white w-full shadow-lg py-4  rounded-lg px-10 max-md:px-2">
        <div className="text-xxl font-semibold  capitalize">
          Professional Memberships
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-gray_out p-2 rounded-md text-gray_out_text my-2">
            <div className="my-4 flex justify-between items-center max-md:flex-col max-md:gap-3 gap-2">
              <input
                placeholder="Name of the Institution"
                {...register("institution_name")}
                className="w-96 border p-3 rounded-md  max-md:w-80"
              />
              <span>From</span>
              <input
                placeholder="from"
                {...register("start_year")}
                type="number"
                className="w-52 border p-3 rounded-md  max-md:w-80"
              />
              <span>To</span>
              <input
                type="number"
                placeholder="from"
                {...register("end_year")}
                className="w-52 border p-3 rounded-md  max-md:w-80"
              />

              <button className="submit-btn-bg-color text-light_tone_one p-4 px-10 rounded-lg">
                Submit
              </button>
            </div>
          </div>
        </form>
        <div className="text-body_font_two flex font-semibold pl-10">
          <span className="w-[600px]">Institute Name</span>
          <span className="w-[600px]">Years of Association</span>
          <span className="w-96">Action</span>
        </div>
        <div className="bg-gray_out p-2 rounded-md my-2 flex flex-col gap-3 pl-10">
          {[...newData, ...membershipData].map((mem) => {
            return (
              <div className="flex   py-1">
                <span className="w-[600px]">{mem.institution_name}</span>
                <span className="w-[600px]">{`${mem.start_year}-${mem.end_year}`}</span>
                <span className="w-96">
                  <img
                    alt=""
                    loading="lazy"
                    src="/assets/images/verified-green-tick.png"
                    className="w-5 h-4"
                  />
                </span>{" "}
              </div>
            );
          })}
        </div>
        <button
          onClick={newData.length ? onSave : () => null}
          disabled={saving}
          className={classNames(
            { "submit-btn-bg-color": newData && newData.length },
            " text-light_tone_one p-4 px-10 rounded-lg my-2"
          )}
        >
          {saving ? "Saving..." : newData && newData.length ? "Save" : "Saved"}
        </button>
      </section>
    </div>
  );
}

export default ProfessionalMemberships;
