import React from "react";
import ErrorPageNotFound from "../../common/ErrorPageNotFound";

function Aboutus() {
  return (
    <>
      <ErrorPageNotFound />
    </>
  );
}

export default Aboutus;
