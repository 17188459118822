import React from "react";
import AppointmentPicker from "./AppointmentPicker";

function MedicalMalpracticeInsurance({ checkFormValidityCheck }) {
  return (
    <div className="my-10 max-md:px-10 ">
      <div className="text-3xl max-md:px-5">Medical Malpractice Insurance</div>
      <div className=" text-body_font_two pt-4 max-md:p-2">
        To complete your registration for Medical Malpractice Insurance,
        <strong>
          please specify your top three preferred date and time slots.
        </strong>
        This information will allow Cure Culture to schedule your appointment
        with the insurance agent. Kindly note that this step is mandatory.
        <AppointmentPicker checkIsValid={checkFormValidityCheck} />
      </div>
    </div>
  );
}

export default MedicalMalpracticeInsurance;
