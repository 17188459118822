import { useContext } from "react";
import { AuthContext } from "../App";
import { AUTH_HEADER, baseURL } from "./utils";
import axios from "axios";
import { toast } from "react-toastify";
import { isAuthorize } from "./useUserActions";

function useContactActions() {
  // const { userDetails, updateUserContext } = useContext(AuthContext);
  const headers = AUTH_HEADER;

  const verifyAdditionalContact = (payload) => {
    return axios
      .post(`${baseURL}/providers/verify_additional_contact`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const saveAdditionalContacts = (payload) => {
    return axios
      .post(`${baseURL}/providers/save_additional_contacts`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };

  const sendVerificationCode = (payload) => {
    return axios
      .post(`${baseURL}/providers/send_verification_code`, payload, headers)
      .then((res) => {
        toast.success(res.data.messages);
        return res.data;
      })
      .catch((err) => {
        toast.error("Something went wrong");
        isAuthorize(err);
      });
  };
  const getAdditionalContact = async (id) => {
    // return {};
    try {
      return await axios.get(
        `${baseURL}/providers/additional_contacts`,
        headers
      );
    } catch (err) {
      toast.error("Something went wrong");
      isAuthorize(err);
    }
  };

  return {
    sendVerificationCode,
    getAdditionalContact,
    saveAdditionalContacts,
  };
}

export default useContactActions;
