import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderDropDownMenu({ title, menuItems }) {
  return (
    <Menu as="div" className="relative inline-block  text-left w-32">
      <div>
        <Menu.Button className="flex text-nowrap   items-center gap-2">
          <div className="">{title}</div>
        

          <FontAwesomeIcon
            icon={faChevronDown}
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Menu.Items}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="flex ">

          {menuItems.map((items, idx) => (
            <Menu.Items
              key={idx}
              className="absolute  z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {items.map((item, idx) => {
                return (
                  <div key={idx} className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={item.path}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          {item.label}
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                );
              })}
            </Menu.Items>
          ))}
        </div>

      </Transition>
    </Menu>
  );
}
